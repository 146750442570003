import React, { Suspense, useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Route, BrowserRouter, Switch, Router } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { useDispatch } from 'react-redux'

import { CURRENT_USER } from '@cms/core/graphql/queries/auth'
import { GET_CURRENT_CUSTOMER } from '@cms/core/graphql/queries/customer'
import Loader from '@cms/core/components/Loader'
import AppLayout from '../AppLayout'

import { Apps } from '@cms/core/Apps'
import { AppProvider } from '../AppProvider'
import { globalHistory } from '../RootLink'
import { CheckModule } from '../CheckModule'
import Route404 from '@cms/core/routes/404Route'

const Container = () => {
    const { data } = useQuery(CURRENT_USER)
    const dispatch = useDispatch()
    const state = useSelector((state) => state)
    const customer = useSelector((state) => state.customer)
    const { data: customerData } = useQuery(GET_CURRENT_CUSTOMER)

    useEffect(() => {
        if (customerData?.currentCustomer) {
            dispatch({
                type: 'SET_CUSTOMER',
                data: customerData?.currentCustomer
            })
        }
    }, [customerData])

    useEffect(() => {
        if (data?.me) {
            dispatch({
                type: 'SET_USER',
                data: {
                    ...data.me,
                    isAdmin:
                        data.me.role === 'superadmin' ||
                        data.me.role === 'admin'
                }
            })
        }
    }, [data])

    const EnabledApps = useMemo(() => {
        return Object.keys(Apps).filter((key) => {
            const App = Apps[key as keyof typeof Apps]
            return !App.checkEnable || App.checkEnable(state)
        })
    }, [state])

    if (!data || !customerData || !customer) return <Loader />

    return (
        <>
            {EnabledApps.map((key) => {
                const App = Apps[key as keyof typeof Apps]

                return (
                    <Switch key={key}>
                        <Route path={App.basePath}>
                            <BrowserRouter basename={App.basePath}>
                                <AppProvider app={App}>
                                    <Suspense fallback={<Loader />}>
                                        <App.container />
                                    </Suspense>
                                </AppProvider>
                            </BrowserRouter>
                        </Route>
                    </Switch>
                )
            })}
        </>
    )
}

export default Container

import React from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { Select, Button, Popconfirm, Checkbox } from 'antd'

export const useCrudActions = ({ buttons, allowed }) => {
    const history = useHistory()

    return buttons?.map(
        (
            { link, warning, action, actionType, title, type, ...buttonProps },
            index
        ) => {
            if (actionType && allowed && !allowed[actionType]) return null

            if (type === 'checkbox') {
                const { children } = buttonProps

                return (
                    <Checkbox
                        key={index}
                        onChange={(e) => {
                            if (action) {
                                action(e.target.checked)
                            }
                        }}
                    >
                        {children}
                    </Checkbox>
                )
            }

            if (type === 'select') {
                const { children } = buttonProps

                if (children.length === 1) return null

                return (
                    <Select
                        key={index}
                        style={{ width: 150 }}
                        placeholder={children[0].label}
                        onChange={(e) => {
                            if (action) {
                                action(e)
                            }
                        }}
                    >
                        {children.map(({ label, value }) => (
                            <Select.Option key={value} value={value}>
                                {label}
                            </Select.Option>
                        ))}
                    </Select>
                )
            }

            if (!warning) {
                return (
                    <Button
                        key={index}
                        type={type || 'primary'}
                        onClick={() => {
                            action ? action() : history.push(link)
                        }}
                        {...buttonProps}
                    />
                )
            }

            return (
                <Popconfirm
                    key={index}
                    title={title}
                    onConfirm={() => action()}
                    okText="Sim"
                    cancelText="Não"
                >
                    <Button {...buttonProps} />
                </Popconfirm>
            )
        }
    )
}

useCrudActions.propTypes = {
    buttons: PropTypes.arrayOf(
        PropTypes.shape({
            link: PropTypes.string,
            warning: PropTypes.string,
            action: PropTypes.func,
            actionType: PropTypes.string,
            title: PropTypes.string,
            type: PropTypes.string
        })
    ).isRequired,
    allowed: PropTypes.object
}


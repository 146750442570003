import { gql } from '@apollo/client'
import * as fragments from '../fragments'

export const GET_EVENT_EDITION_LIST_ORDENABLE = gql`
    query GetEventEditions(
        $first: Int
        $afterCursor: Cursor
        $orderBy: EventEditionOrderBy
        $search: String
    ) {
        eventEditions(
            first: $first
            after: $afterCursor
            orderBy: $orderBy
            search: $search
        ) {
            totalCount
            pageInfo {
                hasNextPage
                endCursor
            }
            edges {
                node {
                    ...EventEditionData
                }
            }
        }
    }

    ${fragments.EventEditionData}
`

export const GET_EVENT_EDITIONS_LIST = gql`
    query GetEventEditionsList($first: Int, $afterCursor: Cursor) {
      eventEditions(first: $first, after: $afterCursor) {
            totalCount
            pageInfo {
                hasNextPage
                endCursor
            }
            edges {
                node {
                    ...EventEditionData
                }
            }
        }
    }

    ${fragments.EventEditionData}
`

export const GET_EVENT_EDITION = gql`
    query GetEventEdition($id: ObjectID!) {
      eventEdition(id: $id) {
            ...EventEditionData
        }
    }

    ${fragments.EventEditionData}
`

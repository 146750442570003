import ReactPlayer from 'react-player'
import styled, { css } from 'styled-components'

export const PlayerWrapper = styled.div`
    position: relative;
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 10px;
    height: 650px;
    
`

export const Player = styled(ReactPlayer)`
    padding: 30px 0;
    margin-top: 80px;
`
export const IconWrapper = styled.div`
    width: 30px;
    height: 30px;
    border-radius: 100%;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
`
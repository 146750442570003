import styled, { css } from 'styled-components'

import { Tabs as TabsAnt } from 'antd'

const { TabPane: TabPaneAnt } = TabsAnt


export const Tabs = styled(TabsAnt)`
 .ant-tabs-nav{
        flex-direction: row-reverse;
    }
    .ant-tabs-extra-content{
        margin: 0 10px 3px 0;
        > div {
            padding: 0;
            > span {
                font-size: 10px;
            }
        }
    }
`

export const InfoCard = styled.div`
    height: 50px;
    background: #434245;
    border-radius: 10px;
    display: flex;
    padding: 0px 10px;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    margin-right: 17px;
    p{
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
    }
    span{
        display: block;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
    }
`

export const WrapperButtons = styled.div`
    width: 45%;
    .ant-btn {
        margin-right: 10px;
    }
    button:hover {
        background-color: #fff;
        color: #000;
    }
`

export const WrapperInfo = styled.div`
    display: flex;
`

export const TabPane = styled(TabPaneAnt)`
    
`
import { gql } from '@apollo/client'
import { BillingPlanData } from '../fragments'

export const GET_BILLING_PLANS_LIST_ORDENABLE = gql`
    query GetBillingPlans(
        $first: Int
        $afterCursor: Cursor
        $orderBy: BillingPlanOrderBy
        $search: String
    ) {
        billingPlans(
            first: $first
            after: $afterCursor
            orderBy: $orderBy
            search: $search
        ) {
            totalCount
            pageInfo {
                hasNextPage
                endCursor
            }
            edges {
                node {
                    ...BillingPlanData
                }
            }
        }
    }

    ${BillingPlanData}
`

export const GET_BILLING_PLAN = gql`
    query GetBillingPlan($id: ObjectID!) {
        billingPlan(id: $id) {
            ...BillingPlanData
        }
    }

    ${BillingPlanData}
`

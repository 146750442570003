import React from 'react'
import { Route } from 'react-router-dom'
import { CheckModuleByPath } from '@cms/core/components/CheckModule'

export const CreateModalRoutes = (Component, path, params = {}) => {
    const RouterComponent = ({ allowed = true }) => (
        <>
            {allowed ? (
                <>
                    <Route
                        exact
                        path={`${path}/adicionar`}
                        render={() => (
                            <Component
                                path={path}
                                params={params}
                                mode="write"
                            />
                        )}
                    />

                    <Route
                        exact
                        path={`${path}/:id/editar`}
                        render={() => (
                            <Component
                                path={path}
                                params={params}
                                mode="write"
                            />
                        )}
                    />
                </>
            ) : (
                <Route
                    exact
                    path={`${path}/:id/visualizar`}
                    render={() => (
                        <Component path={path} params={params} mode="view" />
                    )}
                />
            )}
        </>
    )

    RouterComponent.Custom = (props) => <Route {...props} />

    return RouterComponent
}

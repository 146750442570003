import { gql } from '@apollo/client'

import * as fragments from '../fragments'

export const GET_WATCH_CONFIG = gql`
    query GetWatchConfig {
        watchConfig {
            domain {
                name
            }
            home {
                sections {
                    name
                    active
                    type
                    config
                }
            }
            scripts {
                header
                bodyStart
                bodyEnd
            }
            theme
            translation {
                languages
            }
            loginBackgroundImage {
                ...MediaData
            }
            footerConfig {
                backgroundColor
                links {
                    label
                    url
                }

                social {
                    type
                    url
                }
            }
        }
    }

    ${fragments.MediaData}
`


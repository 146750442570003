import React, {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useState
} from 'react'
import { useParams } from 'react-router-dom'
import { useQuery, useMutation } from '@apollo/client'

import { GET_EVENT } from '@cms/events/graphql/queries/event'

import { UPDATE_EVENT } from '@cms/events/graphql/mutations/event'

import { removeObjectProps, resolveRelateds } from '@cms/events/utils'
import { DEFAULT_THEME } from '../constants/theme'
import { merge } from 'lodash'
import { parseTheme } from '../pages/Theme/utils'
import { useThemeBuilderContext } from '@cms/core/components/ThemeBuilder'

export const StreamingContext = createContext({
    theme: {},
    setTheme: () => null,
    footer: {},
    setFooter: () => {},
    images: {},
    setImages: () => null,
    scripts: {},
    setScripts: () => null,
    configs: {},
    setConfigs: () => null,
    save: () => null
})

export const useStreamingContext = () => useContext(StreamingContext)

export const StreamingProvider = ({ children }) => {
    const { eventId } = useParams()

    const { data, loading } = useQuery(GET_EVENT, {
        variables: { id: eventId }
    })

    const {
        images,
        theme,
        setTheme,
        home,
        setHome,
        menus,
        setMenus,
        configs,
        scripts,
        footer,
        setFooter,
        setImages,
        setConfigs,
        setScripts
    } = useThemeBuilderContext()

    const [updateEvent] = useMutation(UPDATE_EVENT)

    useEffect(() => {
        if (data?.event) {
            const event = removeObjectProps({ ...data.event }, ['__typename'])
            let _themeV1 = event?.theme ? parseTheme(event?.theme) : null
            let _themeV2 = event?.themeV2

            setTheme(merge({}, DEFAULT_THEME, _themeV2 || _themeV1))

            const {
                backgroundImageDesktop,
                backgroundImageHome,
                scheduleImage,
                scheduleImageMobile
            } = event

            setImages({
                backgroundImageDesktop,
                backgroundImageHome,
                scheduleImage,
                scheduleImageMobile
            })

            setScripts({ ...event.scripts })

            const {
                home,
                menus,
                showNumberOfViewers,
                showReactions,
                showNumberOfRegistrations,
                allowStreamingRegistration,
                showNumberOfSpeakers,
                showDates,
                showLiveSponsor,
                showLiveChat,
                showSocialAuth,
                translation,
                chatConfig,
                footerConfig,
                showSpeaperkSocialInfo,
                liveTagText
            } = event

            setFooter(footerConfig)
            setHome(home)
            setMenus(menus)

            setConfigs({
                showReactions,
                showNumberOfViewers,
                showNumberOfRegistrations,
                showNumberOfSpeakers,
                allowStreamingRegistration,
                showDates,
                showLiveSponsor,
                showLiveChat,
                showSocialAuth,
                translation,
                chatConfig,
                showSpeaperkSocialInfo,
                liveTagText
            })
        }
    }, [data])

    const save = useCallback(async () => {
        await updateEvent({
            variables: {
                id: eventId,
                themeV2: theme,
                home,
                scripts: scripts,
                footerConfig: footer,
                ...menus,
                ...configs,
                ...resolveRelateds({ ...images }, [
                    'backgroundImageDesktop',
                    'backgroundImageHome',
                    'scheduleImage',
                    'scheduleImageMobile'
                ])
            }
        })
    }, [eventId, theme, scripts, images, configs, home, menus, footer])

    return (
        <StreamingContext.Provider
            value={{
                home,
                setHome,
                footer,
                setFooter,
                menus,
                setMenus,
                theme,
                setTheme,
                images,
                setImages,
                scripts,
                setScripts,
                configs,
                setConfigs,
                save,
                loading
            }}
        >
            {children}
        </StreamingContext.Provider>
    )
}

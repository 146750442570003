import React, { useEffect, useRef, useCallback, useState, useMemo } from 'react'
import { Button, Divider, Input, Row, Typography } from 'antd'
import { gql, useMutation, useQuery } from '@apollo/client'
import { MessageOutlined, SendOutlined } from '@ant-design/icons'

import { CrudList, CrudColumns } from '@cms/core/components/Crud'

import { GET_CHAT_MESSAGES } from '@cms/events/graphql/queries/chat'
import { SUBSCRIPTION_CHANNEL_MESSAGE } from '@cms/events/graphql/subscription/chat'

import { BigCount, Wrapper } from '@cms/events/components/Dashboard/styles'
import { DashboardCard } from '@cms/events/components/Dashboard'

import { CommentOutlined } from '@ant-design/icons'

import {
    ChatWrapper,
    IconWrapper,
    InnerChatWrapper,
    InputWrapper
} from './styles'
import ChatItem from './ChatItem'
import ChatOptions from './ChatOptions'
import { SEND_MESSAGE } from '@cms/events/graphql/mutations/chat'

const { Text } = Typography

const useRefCallback = (initialValue, fn, deps = []) => {
    const ref = useRef(initialValue)
    const setRef = useCallback(
        (node) => {
            if (node !== null) {
                ref.current = node
                fn(node)
            }
        },
        [fn, ...deps]
    )
    return [ref, setRef]
}

const Chat = ({ talk, token }) => {
    const [initialized, setInitialized] = useState(false)
    const [scrollIsDown, setScrollIsDown] = useState(false)

    const scrollDown = useCallback(() => {
        ref.current.scrollTo(-1)
        setScrollIsDown(true)
    }, [ref, setScrollIsDown])

    const variables = {
        channel: {
            type: 'TALK',
            id: talk
        }
    }

    const query = useQuery(GET_CHAT_MESSAGES, {
        variables,
        notifyOnNetworkStatusChange: false,
        fetchPolicy: 'cache-first'
    })

    const channel = useMemo(() => {
        if (!window.TD_INTERNAL?.WebSocketChannel) {
            return null
        }
        return new window.TD_INTERNAL.WebSocketChannel({
            name: 'chat',
            identify: talk,
            token
        })
    }, [talk, token])

    useEffect(() => {
        if (!channel) {
            return
        }
        const onMessage = ({ actionData, sender }) => {
            query.updateQuery((prev) => {
                return {
                    ...prev,
                    chatMessages: {
                        ...prev.chatMessages,
                        totalCount: prev.chatMessages.totalCount + 1,
                        edges: [
                            ...prev.chatMessages.edges,
                            {
                                __typename: 'ChatMessageEdge',
                                node: {
                                    __typename: 'ChatMessage',
                                    id: actionData.uid,
                                    uid: actionData.uid,
                                    user: {
                                        id: sender.userId,
                                        name: sender.name,
                                        avatar: {
                                            url: sender.avatar
                                        }
                                    },
                                    message: actionData.message
                                }
                            }
                        ]
                    }
                }
            })

            setTimeout(() => {
                scrollDown()
            })
        }

        channel.on('message', onMessage)

        const onDelete = ({ actionData }) => {
            query.updateQuery((prev) => {
                const newEdges = prev.chatMessages.edges.filter(
                    (edge) => edge.node.uid !== actionData.uid
                )
                return {
                    ...prev,
                    chatMessages: {
                        ...prev.chatMessages,
                        totalCount:
                            prev.chatMessages.totalCount -
                            (prev.chatMessages.edges.length - newEdges.length),
                        edges: newEdges
                    }
                }
            })
        }

        channel.on('delete', onDelete)

        channel.connect()

        return () => {
            channel.leave()
            channel.off('message', onMessage)
            channel.off('delete', onDelete)
        }
    }, [query.updateQuery])

    const [ref, setRef] = useRefCallback(
        null,
        (node) => {
            if (node && !initialized && !query.loading) {
                ref.current.scrollTo(-1)
                setScrollIsDown(true)
                setInitialized(true)
            }
        },
        [query.loading]
    )

    const columns = [
        {
            title: 'Message',
            dataIndex: '',
            render: (value, node) => {
                return <ChatItem node={value?.node} />
            }
        },
        {
            title: 'Opções',
            dataIndex: '',
            width: '40px',
            render: (value) => {
                return <ChatOptions node={value?.node} />
            }
        }
    ]

    return (
        <ChatWrapper>
            <Row style={{ marginBottom: '20px' }} align="middle">
                <IconWrapper>
                    <MessageOutlined style={{ color: '#000' }} />
                </IconWrapper>
                <Text style={{ marginLeft: '10px' }}>Chat</Text>
            </Row>
            <InnerChatWrapper>
                <CrudList
                    header={{}}
                    columns={columns}
                    queryName={'chatMessages'}
                    hideAction={'all'}
                    showHeader={false}
                    search={false}
                    transparent
                    //ref={setRef}
                    scrollY={500}
                    reverse={true}
                    {...query}
                    refVT={setRef}
                />
            </InnerChatWrapper>
        </ChatWrapper>
    )
}

export default Chat


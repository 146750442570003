import React, { useMemo } from 'react'
import { AppContext } from '..'
import { findModule } from '../../Sidebar/common'

export const useRootModule = () => {
    const { rootModuleKey, setRootModule, app } = React.useContext(AppContext)

    const rootModule = useMemo(() => {
        const _rootModule = findModule(rootModuleKey, app.modules)
        return _rootModule
    }, [rootModuleKey, app.modules])

    const modules = useMemo(() => {
        return rootModule?.children
    }, [rootModule])

    return { rootModuleKey, setRootModule, rootModule, modules }
}

import { gql } from '@apollo/client'
import * as fragments from '../fragments'

export const GET_FAQ_TOPICS_LIST = gql`
    query GetFaqTopics(
        $first: Int
        $afterCursor: Cursor
        $eventId: ObjectID!
    ) {
      faqTopics(
          first: $first
          after: $afterCursor
          filter: { event: $eventId }
      ) {
          totalCount
          pageInfo {
              hasNextPage
              endCursor
          }
          edges {
              node {
                  ...FaqTopicData
              }
          }
      }
    }

    ${fragments.FaqTopicData}
`

export const GET_FAQ_TOPICS_LIST_ORDERABLE = gql`
    query GetFaqTopics(
        $first: Int
        $afterCursor: Cursor
        $eventId: ObjectID!
        $orderBy: FaqTopicOrderBy
        $search: String
    ) {
      faqTopics(
          first: $first
          after: $afterCursor
          filter: { event: $eventId }
          search: $search
          orderBy: $orderBy
      ) {
          totalCount
          pageInfo {
              hasNextPage
              endCursor
          }
          edges {
              node {
                  ...FaqTopicData
              }
          }
      }
    }

    ${fragments.FaqTopicData}
`

export const GET_FAQ_TOPIC = gql`
    query GetTopic($id: ObjectID!) {
        faqTopic(id: $id) {
            ...FaqTopicData
        }
    }

    ${fragments.FaqTopicData}
`

import React, { useEffect } from 'react'

import Step1 from './Step1'
import Step2 from './Step2'
import Step4 from './Step4'
import Step5 from './Step5'
import Step6 from './Step6'

import EngagementInfo from './engagement/info'
import EngagementRewards from './engagement/rewards'
import EngagementTriggers from './engagement/triggers'

import Steps from '@cms/core/components/TDSteps'

import { Form } from 'antd'
import Context from '../context'

const StepsReferal = ({ type = 'referal', data, onUpdate, onDelete }) => {
    const [form] = Form.useForm()

    const onSave = async () => {
        try {
            await form.validateFields()
        } catch (error) {
            return false
        }

        const variables = form.getFieldsValue()

        variables.id = data.id

        const _variables = JSON.parse(JSON.stringify(variables))

        await onUpdate(_variables)

        return true
    }

    const validateMessages = {
        required: '${label} é obrigatório!',
        types: {
            email: '${label} não é email válido!',
            number: '${label} não é um número válido!'
        },
        number: {
            range: '${label} must be between ${min} and ${max}'
        }
    }

    useEffect(() => {
        form.setFieldsValue(data)
    }, [data])

    const steps =
        type === 'engagement'
            ? [
                  {
                      title: 'Sobre a campanha',
                      component: <EngagementInfo />
                  },
                  {
                      title: 'Recompensas',
                      component: <EngagementRewards />
                  },
                  {
                      title: 'Gatilhos',
                      component: <EngagementTriggers form={form} />
                  }
              ]
            : [
                  {
                      title: 'Sobre a campanha',
                      component: <Step1 />
                  },
                  {
                      title: 'Recompensas',
                      component: <Step2 />
                  },
                  {
                      title: 'Tops',
                      component: <Step6 />
                  },
                  {
                      title: 'Compartilhamento',
                      component: <Step4 />
                  },
                  {
                      title: 'Mensagem',
                      component: <Step5 />
                  }
              ]
    return (
        <Context.Provider value={{ form, onSave }}>
            <Steps
                steps={steps}
                form={form}
                onDelete={onDelete}
                onNext={onSave}
                onPrev={onSave}
                validate={validateMessages}
            />
        </Context.Provider>
    )
}

export default StepsReferal


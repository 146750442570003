import styled from 'styled-components'

export const Card = styled.div`
    border-radius: 40px;
    background-color: rgba(255, 255, 255, 0.2);
    padding: 16px 25px;
    height: 100%;
    width: 100%;

    .container-light {
        margin: 0;
        padding: 0;
    }
    main {
        padding: 0;
        margin: 10px 0 0 0;
    }

    td.ant-table-cell {
        padding-left: 0;
    }

    .minimal-padding {
        td.ant-table-cell,
        th {
            padding-left: 4px;
            padding-right: 4px;
        }
        .td-content-default {
            background-color: transparent;
        }
    }
`


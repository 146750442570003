import React, { useCallback, useEffect, useMemo } from 'react'

import { Row, Form, Col, Affix } from 'antd'
import { merge } from 'lodash'

import { DEFAULT_SECTIONS } from './constants'
import { ConfigFields } from '../Configs/fields'
import { FooterPanelContainer, FooterPanelContent } from './styles'
import { useThemeBuilderContext } from '../../context'

const ThemeConfigFooter = () => {
    const [form] = Form.useForm()

    const {
        footer,
        setFooter,
        getSectionConfig,
        sections: _sections
    } = useThemeBuilderContext()

    const currentConfigs = useMemo(() => {
        const section = getSectionConfig('Footer')
        return {
            title: 'Footer',
            subTitle: 'Configuração do footer',
            sections: DEFAULT_SECTIONS,
            ...section
        }
    }, [_sections])

    const updateFooter = useCallback(
        (values) => {
            setFooter((prev) => merge({}, prev, values))
        },
        [setFooter]
    )

    useEffect(() => {
        if (footer) {
            form.setFieldsValue(footer)
        }
    }, [footer])

    if (!currentConfigs.sections) return null

    const { sections } = currentConfigs

    return (
        <div>
            <Row gutter={[24, 24]}>
                <Col lg={12} sm={24}>
                    <FooterPanelContainer>
                        <Form
                            layout="vertical"
                            form={form}
                            onValuesChange={updateFooter}
                            hideRequiredMark
                        >
                            <FooterPanelContent>
                                {sections.map((section) => {
                                    return (
                                        <ConfigFields fields={section.fields} />
                                    )
                                })}
                            </FooterPanelContent>
                        </Form>
                    </FooterPanelContainer>
                </Col>
                <Col lg={12} sm={24}>
                    <Affix offsetTop={104}>
                        <div></div>
                    </Affix>
                </Col>
            </Row>
        </div>
    )
}

export default ThemeConfigFooter


import { COLORS } from '@cms/core/themes'
import styled from 'styled-components'

export const Modal = styled.div`
    position: fixed;
    right: 0;
    bottom: 10px;
    z-index: 1000;
    overflow: auto;
    outline: 0;
    width: 100%;
    max-width: calc(100vw - 260px);
    height: calc(100vh - 95px);
    transition: width 0.5s;
    background-color: #181118;
    background-image: linear-gradient(
        90deg,
        #181118 0%,
        #101617 65%,
        #0a1817 100%
    );
`

export const Footer = styled.div`
    position: fixed;
    bottom: 0;
    width: 100%;
    max-width: calc(100vw - 260px);
    padding: 5px 60px;
    transition: width 0.5s;
    border-radius: 0px;
    z-index: 1000;
    background: ${COLORS.bgLight};
    border: 1px solid ${COLORS.textDark};

    > div {
        display: flex;
        justify-content: space-between;
        width: 100%;
    }
`


import React from 'react'
import { useHistory } from 'react-router-dom'
import { useApolloClient } from '@apollo/client'

import { Button } from 'antd'
import { REQUEST_REPORT } from '@cms/events/graphql/mutations/reports'
import { addToList } from '@cms/core/graphql/utils'

const ReportButton = ({ eventId, formId }) => {
    const client = useApolloClient()
    const history = useHistory()

    return (
        <Button
            className="ant-btn ant-btn-primary ant-btn-lg"
            style={{
                marginLeft: 'auto',
                marginTop: '18px',
                marginBottom: '18px'
            }}
            onClick={() => {
                const variables = {
                    type: 'progressiveform',
                    event: eventId,
                    params: {
                        formId
                    }
                }

                client.mutate({
                    mutation: REQUEST_REPORT,
                    update: addToList({
                        list: 'reports',
                        Type: 'Report'
                    }),
                    variables
                })
                history.push('/evento/' + eventId + '/reports')
            }}
        >
            Exportar capturas do formulário
        </Button>
    )
}

export default ReportButton

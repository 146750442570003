import { useQuery } from '@apollo/client'
import { GET_COUNT_PEAK_AUDIENCE } from '@cms/events/graphql/queries/blocks/totals'
import { renderBaseK } from '@cms/core/utils'
import React from 'react'

const PeakTime = ({ eventId, talk }) => {
    const { loading, error, data } = useQuery(GET_COUNT_PEAK_AUDIENCE, {
        variables: { eventId, talk }
    })

    if (loading || error) return null

    return (
        <p>
            {data?.countPeakAudience ? renderBaseK(data.countPeakAudience) : 0}
            <span>Maior pico</span>
        </p>
    )
}

export default PeakTime

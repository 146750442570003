import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Form, Select, Input } from 'antd'

import { Colors, FontSelector } from '../../components'
import { Wrapper, Title } from '../../styles'
import { useWidgetContext } from '../../context/widgets'

const Fields = () => {
    return (
        <>
            <FontSelector />
            <Form.Item
                name={['configs', 'event']}
                label="Texto"
                style={{ display: 'none' }}
            >
                <Input />
            </Form.Item>
            {/* <Wrapper transparent> */}
            <Title level={4}>Cores</Title>
            <Form.Item name={['configs', 'color']} label="Cor do texto">
                <Colors />
            </Form.Item>
            <Form.Item name={['configs', 'logo']} label="Logo">
                <Select>
                    <Option value="default">Original</Option>
                    <Option value="white">Branco</Option>
                    <Option value="black">Preto</Option>
                </Select>
            </Form.Item>
            {/* </Wrapper> */}
        </>
    )
}
const Preview = ({ db }) => {
    const { eventId } = useParams()

    if (!db) return null

    return (
        <div
            data-x-widget="speakers"
            data-x-customer={db}
            data-x-preview="true"
            data-x-event={eventId}
            // data-x-event="5f5f6ac9a7dd244f0b6d14bc"
            data-x-font="Montserrat"
            data-x-color="#000"
            data-x-logo="black"
            // data-x-id="widget"
        />
    )
}

export const Speakers = {
    Fields,
    Preview
}

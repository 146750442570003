import { gql } from '@apollo/client'
import * as fragments from '../fragments'

export const GET_BRAND_OPTIONS = gql`
    query GetBrands($filter: BrandFilters) {
        brands(filter: $filter, orderBy: { name: ASC }) {
            totalCount
            pageInfo {
                hasNextPage
                endCursor
            }
            edges {
                node {
                    name
                    id
                    page {
                        slug
                    }
                }
            }
        }
    }
`

export const GET_BRAND_LIST = gql`
    query GetBrands(
        $first: Int
        $afterCursor: Cursor
        $eventId: ObjectID
        $sort: SortDirection
        $search: String
    ) {
        brands(
            first: $first
            after: $afterCursor
            filter: { event: $eventId }
            orderBy: { name: $sort }
            search: $search
        ) {
            totalCount
            pageInfo {
                hasNextPage
                endCursor
            }
            edges {
                node {
                    ...BrandData
                }
            }
        }
    }

    ${fragments.BrandData}
`

export const GET_BRAND_LIST_ORDENABLE = gql`
    query GetBrands(
        $first: Int
        $afterCursor: Cursor
        $eventId: ObjectID
        $orderBy: BrandOrderBy
        $search: String
        $plan: ObjectID
    ) {
        brands(
            first: $first
            after: $afterCursor
            filter: { event: $eventId, plan: $plan }
            orderBy: $orderBy
            search: $search 
        ) {
            totalCount
            pageInfo {
                hasNextPage
                endCursor
            }
            edges {
                node {
                    ...BrandData
                }
            }
        }
    }

    ${fragments.BrandData}
`

export const GET_BRAND = gql`
    query GetBrand($id: ObjectID!) {
        brand(id: $id) {
            ...BrandData
        }
    }

    ${fragments.BrandData}
`

export const GET_BRAND_BY_EVENT = gql`
    query GetBrand($eventId: ObjectID!) {
        userBrands(event: $eventId) {
            ...BrandData
        }
    }

    ${fragments.BrandData}
`

export const GET_BRAND_VIEW_BY_DAY = gql`
    query GetBrandViewByDay(
        $eventId: ObjectID!
        $brandId: ObjectID
        $limit: Int
    ) {
        brandViewByDay(
            filter: { event: $eventId, brand: $brandId }
            limit: $limit
        ) {
            date
            total
        }
    }
`

export const GET_BRAND_BY_SOCIAL = gql`
    query GetBrandBySocial($eventId: ObjectID!, $brandId: ObjectID) {
        brandBySocial(filter: { event: $eventId, brand: $brandId }) {
            name
            total
            percentage
        }
    }
`

export const GET_BRAND_SPONSOR = gql`
    query GetBrandsSponsor($sponsor: ObjectID!) {
        brands(
            first: 1
            filter: { sponsor: $sponsor }
            orderBy: { updatedAt: DESC }
        ) {
            totalCount
            pageInfo {
                hasNextPage
                endCursor
            }
            edges {
                node {
                    ...BrandData
                }
            }
        }
    }
    ${fragments.BrandData}
`

export const GET_BRAND_VIEW_USERS = gql`
    query GetBrandsViewUsers(
        $first: Int
        $afterCursor: Cursor
        $eventId: ObjectID!
        $brandId: ObjectID
    ) {
        brandViewsUser(
            first: $first
            after: $afterCursor
            filter: { event: $eventId, brand: $brandId }
        ) {
            totalCount
            pageInfo {
                hasNextPage
                endCursor
            }
            edges {
                node {
                    id
                    user {
                        name
                    }
                    data
                }
            }
        }
    }
`

export const GET_BRAND_VIEWS = gql`
    query GetBrandsViews(
        $first: Int
        $afterCursor: Cursor
        $eventId: ObjectID!
        $orderBy: BrandViewOrderBy
    ) {
        brandViews(
            first: $first
            after: $afterCursor
            filter: { event: $eventId }
            orderBy: $orderBy
        ) {
            totalCount
            pageInfo {
                hasNextPage
                endCursor
            }
            edges {
                node {
                    views
                    brand {
                        name
                    }
                }
            }
        }
    }
`

export const GET_BRAND_TOP_VIEWS = gql`
    query GetBrandsTopViews($eventId: ObjectID!) {
        topBrandViews(filter: { event: $eventId }, orderBy: { views: DESC }) {
            edges {
                node {
                    name
                    views
                }
            }
        }
    }
`

export const GET_BRAND_TAGS = gql`
    query GetBrandTags($eventId: ObjectID!) {
        brandTags(event: $eventId)
    }
`

export const GET_BRAND_DOWNLOADS = gql`
    query GetBrandDownloads($eventId: ObjectID!, $brandId: ObjectID) {
        brandDownloads(filter: { event: $eventId, brand: $brandId }) {
            name
            total
            percentage
        }
    }
`

export const GET_BRAND_BY_COUNTRY = gql`
    query GetBrandByCountry($eventId: ObjectID!, $brandId: ObjectID) {
        brandByCountry(filter: { event: $eventId, brand: $brandId }) {
            name
            total
            percentage
        }
    }
`

export const GET_BRANDS_VIEWS_TOTAL = gql`
    query GetBrandViewTotal($eventId: ObjectID!, $brandId: ObjectID) {
        brandViewsTotal(filter: { event: $eventId, brand: $brandId })
    }
`

export const GET_BRANDS_CONTACT_TOTAL = gql`
    query GetBrandContactTotal($eventId: ObjectID!, $brandId: ObjectID) {
        brandContactTotal(filter: { event: $eventId, brand: $brandId })
    }
`

export const GET_BRANDS_ACTION = gql`
    query GetBrandAction($eventId: ObjectID!, $brandId: ObjectID) {
        brandAction(filter: { event: $eventId, brand: $brandId }) {
            name
            total
            percentage
        }
    }
`

export const GET_BRANDS_TOTAL = gql`
    query GetBrandTotal($eventId: ObjectID!) {
        brands(filter: { event: $eventId }) {
            totalCount
        }
    }
`

export const typesReportConfig = {
    leads: {
        name: 'Leads do Evento',
        active: true
    },
    chatMessages: {
        name: 'Listagem de Mensagens por Palestra - Chat',
        requestTalk: true,
        active: true
    },
    brandviews: {
        name: 'Listagem de Acesso por Patrocinador',
        active: true
    },
    subscriptionsPerDay: {
        name: 'Inscrições por dia',
        active: true
    },
    rewards: {
        name: 'Listagem de Ganhadores - Indicação',
        active: true
    },
    engagementRewards: {
        name: 'Listagem de Ganhadores - Engajamento',
        active: true
    },

    talkwatched: {
        name: 'Expectadores da Palestra',
        requestTalk: true,
        active: true
    },

    talks: {
        name: 'Audiência por Palestras'
    },

    talkviews: {
        name: 'Espectadores da Palestra Analítico',
        requestTalk: true
    },
    talkviewsfull: {
        name: 'Espectadores da Palestra Analítico - Formulário',
        requestTalk: true
    },
    talkviewsBI: {
        name: 'Espectadores do Evento por Palestra'
    },
    referencial: {
        name: 'Programa de Indicação'
    },
    overall: {
        name: 'Performance dos Eventos',
        requestDateRange: true
    },
    progressiveform: {
        name: 'Formulário Complementar',
        active: true,
        params: {
            formId: 'Formulário'
        }
    },
    tickets: {
        name: 'Ingressos',
        active: true
    }
}

export const typesReport = Object.keys(typesReportConfig)
    .map((key) => ({
        value: key,
        ...typesReportConfig[key]
    }))
    .filter((item) => item.active)

import React from 'react'

import { WatchProvider, useWatchContext } from './context'
import { BASE_URL, NAV_ROUTES } from './constants/routes'
import { ThemeBuilderProvider } from '@cms/core/components/ThemeBuilder'
import { ThemeBuilderContent } from '@cms/core/components/ThemeBuilder/components/Content'

const WatchConfigContent = () => {
    const { save } = useWatchContext()

    return <ThemeBuilderContent save={save} />
}

const WatchConfigScreen = () => {
    return (
        <ThemeBuilderProvider
            routesConfig={{ baseUrl: BASE_URL, routes: NAV_ROUTES }}
        >
            <WatchProvider>
                <WatchConfigContent />
            </WatchProvider>
        </ThemeBuilderProvider>
    )
}

export default WatchConfigScreen


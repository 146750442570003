import React from 'react'

import dayjs from 'dayjs'
import { Space, Typography } from 'antd'
import _get from 'lodash/get'
import { useParams } from 'react-router-dom'
import { useCreation } from '@umijs/hooks'
import { useQuery } from '@apollo/client'
import { useSelector } from 'react-redux'
import {
    CrudColumns,
    CrudList,
    CreateModalRoutes
} from '@cms/core/components/Crud'
import { ButtonLink } from '@cms/events/components/Buttons'
import { GET_SUBSCRIPTIONS_LIST } from '@cms/events/graphql/queries/subscriptions'
import { GET_EVENT_FIELDS } from '@cms/events/graphql/queries/event'

import SubscriptionModal from './ModalFull'
import SubscriptionTicket from './AddTicketToUser'
import { formatField } from '@td/commons/fields/formatter'
import SubscriptionTickets from './SubscriptionTickets'
import TransferTicket from './TransferTicket'

const { Text } = Typography

const getFieldValue = (field, userData, path, defautlValue = '---') => {
    let value = _get(userData?.data, path)
    if (!value) value = _get(userData?.data?.customFields, path)
    if (!value) value = _get(userData?.user, path)
    value = formatField(field, value)
    return value || defautlValue
}

const Subscriptions = (props) => {
    const { eventId } = useParams()

    const customerModules = useSelector(
        (state) => state.customer?.modules || []
    )

    const query = useQuery(GET_SUBSCRIPTIONS_LIST, {
        variables: { first: 25, eventId, orderBy: { createdAt: 'DESC' } }
    })

    const eventFields = useQuery(GET_EVENT_FIELDS, {
        variables: { id: eventId }
    })

    const orderByFields = [
        { label: 'Nome', value: 'syncUser.name' },
        { label: 'Data criação', value: 'createdAt' }
    ]

    const path = `/evento/${eventId}/inscricoes`

    const ModalRoutes = useCreation(() => {
        const Routers = CreateModalRoutes(SubscriptionModal, path, { eventId })
        return () => (
            <>
                <Routers.Custom
                    path={`${path}/:id/detalhes/:userId`}
                    render={() => (
                        <SubscriptionModal path={path} eventId={eventId} />
                    )}
                />
                <Routers.Custom
                    path={`${path}/:id/tickets/:userId`}
                    render={() => (
                        <SubscriptionTickets
                            path={path}
                            params={{ eventId: eventId }}
                        />
                    )}
                />
                <Routers.Custom
                    path={`${path}/:id/tickets/:userId/add-ticket`}
                    render={() => (
                        <SubscriptionTicket
                            path={path}
                            params={{ eventId: eventId }}
                        />
                    )}
                />
                <Routers.Custom
                    path={`${path}/:id/tickets/:userId/transfer/:ticketOwnershipId`}
                    render={() => (
                        <TransferTicket
                            path={path}
                            params={{ eventId: eventId }}
                        />
                    )}
                />
            </>
        )
    }, [eventId])

    const columns = [
        {
            title: 'Nome',
            dataIndex: [''],
            width: 300,
            render: (value, obj) => {
                return (
                    <CrudColumns
                        title={obj.node.user?.name}
                        image={obj.node.user?.avatar?.url}
                        text={[
                            obj.node.user.email,
                            `Inscrito: ${dayjs(
                                new Date(obj.node.createdAt)
                            ).format('DD MMM YYYY')}`
                        ]}
                    />
                )
            }
        }
    ]

    if (customerModules.includes('TICKET')) {
        columns.push({
            title: 'Tipo de ingresso',
            dataIndex: [''],
            width: 180,
            render: (value, obj) => {
                value = obj.node.ticketOwnership?.ticket?.name || '---'
                return (
                    <CrudColumns
                        onlyText
                        title="Tipo de ingresso"
                        text={[value]}
                    />
                )
            }
        })
    }

    if (eventFields.data?.event.fields) {
        eventFields.data?.event.fields.forEach((field, i) => {
            columns.push({
                title: field.label,
                dataIndex: [''],
                width: 200,
                render: (_, obj) => {
                    const value = getFieldValue(field, obj.node, field.name)
                    return (
                        <CrudColumns
                            key={i}
                            onlyText
                            title={field.label}
                            text={[value]}
                        />
                    )
                }
            })
        })
    }

    const actionsExtra = {
        dataIndex: '',
        key: 'x',
        width: 300,
        fixed: 'right',
        align: 'right',
        render: (text, record) => {
            const id = record.node.id

            return (
                <Space style={{ paddingRight: '10px' }}>
                    <ButtonLink
                        id={id}
                        path={`detalhes/${record.node.user.id}`}
                        key={id}
                        text={'Detalhes'}
                    />
                    <ButtonLink
                        id={id}
                        path={`tickets/${record.node.user.id}`}
                        key={id}
                        text={'Ingressos'}
                    />
                </Space>
            )
        }
    }

    return (
        <>
            <ModalRoutes />
            <CrudList
                header={{
                    title: 'Inscrições',
                    countNames: {
                        singular: 'Inscricão',
                        plural: 'Inscricões'
                    }
                }}
                style={{
                    height: '15vh'
                }}
                columns={columns}
                queryName={'eventRegistrations'}
                hideAction={'all'}
                orderByFields={orderByFields}
                search={true}
                orderByParams={'leads'}
                actionsExtra={actionsExtra}
                actionFixed={true}
                scrollY={'100%'}
                {...query}
            />
        </>
    )
}

export default Subscriptions


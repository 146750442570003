import React from 'react'

import { useMutation, useQuery } from '@apollo/client'

import {
    CreateModalRoutes,
    CrudColumns,
    CrudList
} from '@cms/core/components/Crud'
import { GET_WATCH_PLANS_LIST_ORDENABLE } from '@cms/watch/graphql/queries/watchPlan'

import { DELETE_WATCH_PLAN } from '@cms/watch/graphql/mutations/watchPlan'

import WatchPlansModal from './WatchPlansModal'
import { removeFromList } from '@cms/watch/graphql/utils'

const path = `/planos`
const ModalRoutes = CreateModalRoutes(WatchPlansModal, path, {})

const columns = [
    {
        title: 'Nome',
        dataIndex: '',
        render: (value, obj) => {
            return <CrudColumns title={obj.node.name} onlyText />
        }
    }
]

const orderByFields = [
    { label: 'Nome', value: 'name' },
    { label: 'Data criação', value: 'createdAt' }
]

const WatchPlans = () => {
    const query = useQuery(GET_WATCH_PLANS_LIST_ORDENABLE, {
        variables: { first: 25, orderBy: { name: 'ASC' } }
    })

    const [deleteData] = useMutation(DELETE_WATCH_PLAN, {
        update: removeFromList({ list: 'watchPlans', Type: 'WatchPlan' })
    })

    if (query.error) return <div>Error</div>

    return (
        <>
            <ModalRoutes />
            <CrudList
                header={{
                    title: 'Planos',
                    subTitle: 'Adicione e gerencie planos',
                    buttons: [
                        {
                            children: 'Novo Plano',
                            type: 'primary',
                            link: path + '/adicionar'
                        }
                    ]
                }}
                columns={columns}
                search={true}
                queryName={'watchPlans'}
                onDelete={deleteData}
                orderByFields={orderByFields}
                {...query}
            />
        </>
    )
}

export default WatchPlans

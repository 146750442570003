import React from 'react'
import { useQuery, useMutation } from '@apollo/client'

import { CrudList, CreateModalRoutes } from '@cms/core/components/Crud'

import { GET_BRAND_PLAN_LIST_ORDENABLE } from '@cms/events/graphql/queries/brandPlan'
import { DELETE_BRAND_PLAN } from '@cms/events/graphql/mutations/brandPlan'

import BrandPlanModal from './BrandPlansModal'
import { removeFromList } from '@cms/core/graphql/utils'

const path = '/principal/patrocinador/planos'

const ModalRoutes = CreateModalRoutes(BrandPlanModal, path)

const columns = [
    {
        title: 'Nome',
        dataIndex: ['node', 'name']
    },
    {
        title: 'Tipo',
        dataIndex: ['node', 'type']
    }
]

const orderByFields = [
    { label: 'Nome', value: 'name' },
    { label: 'Data criação', value: 'createdAt' },
    { label: 'Prioridade', value: 'priority' }
]

const BrandPlanList = () => {
    const query = useQuery(GET_BRAND_PLAN_LIST_ORDENABLE, {
        variables: { first: 25, orderBy: { createdAt: 'DESC' } }
    })

    const [deleteData] = useMutation(DELETE_BRAND_PLAN, {
        update: removeFromList({ list: 'brandPlans', Type: 'BrandPlan' })
    })

    if (query.error) return <div>Error: {query.error}</div>

    return (
        <>
            <ModalRoutes />
            <CrudList
                header={{
                    title: 'Planos de Patrocinador',
                    subTitle: 'Adicione e gerencie planos de patrocinador',
                    buttons: [
                        {
                            children: 'Novo Plano',
                            type: 'primary',
                            link: path + '/adicionar'
                        }
                    ]
                }}
                search={true}
                columns={columns}
                queryName={'brandPlans'}
                onDelete={deleteData}
                orderByFields={orderByFields}
                {...query}
            />
        </>
    )
}

export default BrandPlanList

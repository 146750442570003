import styled from 'styled-components'

export const ChatWrapper = styled.div`
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 10px;
    height: 95%;
    padding: 20px;
    .td-content-default {
        margin: 0;
        padding: 0;
    }
    .ant-table-cell{
        border: none;
    }
`
export const IconWrapper = styled.div`
    width: 30px;
    height: 30px;
    border-radius: 100%;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
`

export const InputWrapper = styled.div`
    margin-top: 20px;
    .ant-btn {
        border-color: linear-gradient(250.11deg, #4518DB -7.22%, #7B29FF 103.84%);
        position: absolute;
        right: 37px;
        border-radius: 5px;
        height: 38px;
        margin-top: 1px;

    }

`
export const InnerChatWrapper = styled.div`
height: 100%;
`
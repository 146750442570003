import { Headers } from './Headers'
import { Sponsors } from './Sponsors'
import { SponsorsCarousel } from './SponsorCarousel'
import { EventsNumbers } from './EventNumbers'
import { Abouts } from './About'
import { Learns } from './Learn'
import { SpeakersSections } from './Speakers'
import { SpeakersCarousel } from './SpeakerCarousel'
import { FeaturedSpeakersSections } from './FeaturedSpeakers'
import { RewardsSections } from './Rewards'
import { Schedules } from './Schedules'
import { Ctas } from './Cta'
import { Countdowns } from './Countdown'
import { Footers } from './Footers'
import { Owners } from './Owners'
import { Addresses } from './Address'
import { Virals } from './Viral'
import { Topics } from './Topic'
import { Faqs } from './Faq'
import { Images } from './Image'
import { Forms } from './Forms'
import { Testimonials } from './Testimonial'
import { Galeries } from './Galeries'
import { Cards } from './Cards'
import { Codes } from './Codes'
import { Ticker } from './Ticker'

export default {
    ...Headers,
    ...Sponsors,
    ...SponsorsCarousel,
    ...Ticker,
    ...EventsNumbers,
    ...Abouts,
    ...Learns,
    ...SpeakersSections,
    ...SpeakersCarousel,
    ...FeaturedSpeakersSections,
    ...RewardsSections,
    ...Schedules,
    ...Ctas,
    ...Countdowns,
    ...Footers,
    ...Faqs,
    ...Owners,
    ...Addresses,
    ...Virals,
    ...Topics,
    ...Galeries,
    ...Testimonials,
    ...Images,
    ...Forms,
    ...Cards,
    ...Codes
}

import React from 'react'

import { PageHeader } from '@ant-design/pro-layout'

import { Layout, Row, Col, Tabs } from 'antd'

import { useParams } from 'react-router-dom'

import { ButtonReturn } from '@cms/events/components/Buttons'
import { DashboardTabs } from '@cms/events/components/Dashboard/styles'

import { GET_BRAND } from '@cms/events/graphql/queries/brand'

import { useQuery } from '@apollo/client'

import { SponsorDashboardProfile } from '@cms/events/components/Dashboard'

import { BrandSelectProvider } from '@cms/events/components/Container/SponsorDetail/SelectBrand/context'

const { Content } = Layout
const { TabPane } = Tabs

const BrandsDashboard = ({ params, ...props }) => {
    const { eventId } = useParams()
    const { id: brandId } = useParams()

    const { data, loading, error } = useQuery(GET_BRAND, {
        variables: { id: brandId }
    })

    if (loading || error) return null

    return (
        <BrandSelectProvider brandId={brandId}>
            <Layout>
                <Content>
                    <PageHeader style={{ padding: '16px 0px' }} />
                    <Row gutter={24}>
                        <Col span={24}>
                            <DashboardTabs
                                defaultActiveKey="1"
                                tabBarExtraContent={<ButtonReturn />}
                            >
                                <TabPane tab={data.brand.name} key="1">
                                    <SponsorDashboardProfile
                                        eventId={eventId}
                                        brandId={brandId}
                                    />
                                </TabPane>
                            </DashboardTabs>
                        </Col>
                    </Row>
                </Content>
            </Layout>
        </BrandSelectProvider>
    )
}

export default BrandsDashboard

import React from 'react'

import { useMutation, useQuery } from '@apollo/client'
import { useCreation } from '@umijs/hooks'
import {
    CreateModalRoutes,
    CrudColumns,
    CrudList
} from '@cms/core/components/Crud'
import { GET_WATCH_CONTENTS_ORDENABLE } from '@cms/watch/graphql/queries/watchContent'

import { DELETE_WATCH_CONTENT } from '@cms/watch/graphql/mutations/watchContent'
import { removeFromList } from '@cms/watch/graphql/utils'

import ContentsModal from './ContentsModal'

const columns = [
    {
        title: 'Título',
        dataIndex: '',
        render: (value, obj) => {
            return <CrudColumns title={obj.node.title} onlyText />
        }
    },
    {
        title: 'Categoria',
        dataIndex: '',
        render: (value, obj) => {
            return (
                <CrudColumns title={obj.node?.categories[0]?.name} onlyText />
            )
        }
    }
]

const orderByFields = [
    { label: 'Título', value: 'title' },
    { label: 'Data criação', value: 'createdAt' }
]

const WatchContents = ({ module = 'VIDEO' } = {}) => {
    const query = useQuery(GET_WATCH_CONTENTS_ORDENABLE, {
        variables: {
            first: 25,
            module,
            orderBy: {
                createdAt: 'DESC'
            }
        }
    })

    const [deleteData] = useMutation(DELETE_WATCH_CONTENT, {
        update: removeFromList({ list: 'watchContents', Type: 'WatchContent' })
    })

    const path = module === 'COURSE' ? '/cursos/videos' : '/conteudo/videos'

    const ModalRoutes = useCreation(() => {
        return CreateModalRoutes(ContentsModal, path, { module })
    }, [module, path])

    if (query.error) return <div>Error</div>

    return (
        <>
            <ModalRoutes />
            <CrudList
                header={{
                    title: 'Conteúdos',
                    subTitle: 'Adicione e gerencie seus conteúdos',
                    buttons: [
                        ...(module === 'VIDEO'
                            ? [
                                  {
                                      children: 'Importar Conteúdo',
                                      type: 'primary',
                                      link: path + '/importacao'
                                  }
                              ]
                            : []),
                        {
                            children: 'Novo Conteúdo',
                            type: 'primary',
                            link: path + '/adicionar'
                        }
                    ]
                }}
                columns={columns}
                search={true}
                queryName={'watchContents'}
                onDelete={deleteData}
                orderByFields={orderByFields}
                {...query}
            />
        </>
    )
}

export default WatchContents

import { gql } from '@apollo/client'
import * as fragments from '../fragments'

export const GET_CATEGORY_LIST = gql`
    query GetCategories($first: Int, $afterCursor: Cursor, $eventId: ObjectID) {
        categories(
            first: $first
            after: $afterCursor
            filter: { event: $eventId }
        ) {
            totalCount
            pageInfo {
                hasNextPage
                endCursor
            }
            edges {
                node {
                    ...CategoryData
                }
            }
        }
    }

    ${fragments.CategoryData}
`

export const GET_CATEGORY_LIST_ORDERABLE = gql`
    query GetCategories(
        $first: Int
        $afterCursor: Cursor
        $eventId: ObjectID
        $type: CategoryType
        $orderBy: CategoryOrderBy
        $search: String
    ) {
        categories(
            first: $first
            after: $afterCursor
            filter: { event: $eventId, type: $type }
            search: $search
            orderBy: $orderBy
        ) {
            totalCount
            pageInfo {
                hasNextPage
                endCursor
            }
            edges {
                node {
                    ...CategoryData
                }
            }
        }
    }

    ${fragments.CategoryData}
`

export const GET_CATEGORY = gql`
    query GetCategory($id: ObjectID!) {
        category(id: $id) {
            ...CategoryData
        }
    }

    ${fragments.CategoryData}
`
export const GET_CATEGORY_SELECT_LIST = gql`
    query GetCategories(
        $first: Int
        $eventId: ObjectID
        $type: CategoryType
        $orderBy: CategoryOrderBy
    ) {
        categories(
            first: $first
            filter: { event: $eventId, type: $type }
            orderBy: $orderBy
        ) {
            edges {
                node {
                    label: name
                    value: id
                }
            }
        }
    }
`


import React from 'react'
import { Col, Collapse } from 'antd'
import { TextInformation, RowTitle, Table, Row } from './styles'
import { parseDomain } from 'parse-domain'
import { useSelector } from 'react-redux'

const { Panel } = Collapse

const TD_CNAME =
    location.hostname.indexOf('dev.') === 0
        ? 'dev-dns.transformacaodigital.com'
        : 'event-dns.transformacaodigital.com'

const TD_IP =
    location.hostname.indexOf('dev.') === 0 ? '146.148.42.27' : '54.232.144.4'

const CollapseDomain = ({ field = 'domain', domainName }) => {
    const parse = parseDomain(domainName)
    const state = useSelector((state) => state)

    if (!parse) return null

    const { subDomains = [] } = parse

    const isSubdomain = !!subDomains.length

    const recordKey = isSubdomain ? subDomains.join('.') : domainName

    const renderValue = () => {
        switch (field) {
            case 'streamingDomain':
                return isSubdomain ? 'web.dns.app.td' : '76.76.21.21'
            default:
                return isSubdomain ? 'sites.dns.app.td' : '76.76.21.21'
        }
    }

    return (
        <Collapse style={{ marginBottom: 20 }}>
            <Panel header="Instalação" key="1">
                <Row>
                    <TextInformation>
                        Crie um novo registro conforme a tabela abaixo. Depois
                        de concluir a criação dos registros no seu servidor.
                    </TextInformation>
                </Row>

                <Table>
                    <RowTitle style={{ width: '100%' }}>
                        <Col span={8}>
                            <TextInformation>Name</TextInformation>
                        </Col>

                        <Col span={8}>
                            <TextInformation>Type</TextInformation>
                        </Col>

                        <Col span={8}>
                            <TextInformation>Value</TextInformation>
                        </Col>
                    </RowTitle>

                    <>
                        <Row style={{ width: '100%' }}>
                            <Col span={8}>
                                <TextInformation>{recordKey}</TextInformation>
                            </Col>
                            <Col span={8}>
                                <TextInformation>
                                    {isSubdomain ? 'CNAME' : 'A'}
                                </TextInformation>
                            </Col>
                            <Col span={8}>
                                <TextInformation>
                                    {renderValue()}
                                </TextInformation>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={8}>
                                <TextInformation>
                                    __tdvalidate.watch
                                </TextInformation>
                            </Col>
                            <Col span={8}>
                                <TextInformation>TXT</TextInformation>
                            </Col>
                            <Col span={8}>
                                <TextInformation>
                                    td-watch-verify={state?.customer?.db}
                                </TextInformation>
                            </Col>
                        </Row>
                    </>
                </Table>
            </Panel>
        </Collapse>
    )
}

export default CollapseDomain


import { gql } from '@apollo/client'
import * as fragments from '../fragments'

export const GET_LOCATION_LIST_ORDENABLE = gql`
    query GetLocation(
        $first: Int
        $afterCursor: Cursor
        $orderBy: LocationOrderBy
        $eventId: ObjectID!
        $search: String
    ) {
        locations(
            first: $first
            after: $afterCursor
            orderBy: $orderBy
            search: $search
            filter: { event: $eventId }
        ) {
            totalCount
            pageInfo {
                hasNextPage
                endCursor
            }
            edges {
                node {
                    ...LocationData
                }
            }
        }
    }

    ${fragments.LocationData}
`

export const GET_LOCATIONS_LIST = gql`
    query GetLocationList($first: Int, $afterCursor: Cursor) {
        locations(first: $first, after: $afterCursor) {
            totalCount
            pageInfo {
                hasNextPage
                endCursor
            }
            edges {
                node {
                    ...LocationData
                }
            }
        }
    }

    ${fragments.LocationData}
`

export const GET_LOCATION = gql`
    query GetLocation($id: ObjectID!) {
        location(id: $id) {
            ...LocationData
        }
    }

    ${fragments.LocationData}
`

import { gql } from '@apollo/client'
import * as fragments from '../fragments'

export const GET_COLLECTION_ITEMS_LIST = gql`
    query GetCollectionItems(
        $first: Int
        $afterCursor: Cursor
        $eventId: ObjectID!
        $categoryId: ObjectID
    ) {
        collectionItems(
            first: $first
            after: $afterCursor
            filter: { event: $eventId, category: $categoryId }
        ) {
            totalCount
            pageInfo {
                hasNextPage
                endCursor
            }
            edges {
                node {
                    ...CollectionItemData
                }
            }
        }
    }

    ${fragments.CollectionItemData}
`

export const GET_COLLECTION_ITEMS_LIST_ORDERABLE = gql`
    query GetCollectionItems(
        $first: Int
        $afterCursor: Cursor
        $eventId: ObjectID!
        $categoryId: ObjectID
        $orderBy: CollectionItemOrderBy
        $search: String
    ) {
        collectionItems(
            first: $first
            after: $afterCursor
            filter: { event: $eventId, category: $categoryId }
            search: $search
            orderBy: $orderBy
        ) {
            totalCount
            pageInfo {
                hasNextPage
                endCursor
            }
            edges {
                node {
                    ...CollectionItemData
                }
            }
        }
    }

    ${fragments.CollectionItemData}
`

export const GET_COLLECTION_ITEM = gql`
    query GetCollectionItem($id: ObjectID!) {
        collectionItem(id: $id) {
            ...CollectionItemData
        }
    }

    ${fragments.CollectionItemData}
`
export const GET_COLLECTION_ITEMS_SELECT_LIST = gql`
    query GetCollectionItemsSelectList(
        $eventId: ObjectID!
        $categoryId: ObjectID
    ) {
        collectionItems(filter: { event: $eventId, category: $categoryId }) {
            edges {
                node {
                    value: id
                    label: title
                }
            }
        }
    }
`

export const GET_COLLECTION_ITEM_TAGS = gql`
    query GetCollectionItemTags($eventId: ObjectID!) {
        collectionItemTags(event: $eventId)
    }
`

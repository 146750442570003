import { gql } from '@apollo/client'
import * as fragments from '../fragments'

export const GET_SPEAKER_OPTIONS = gql`
    query GetSpeakers($filter: SpeakerFilters) {
        speakers(filter: $filter, orderBy: { name: ASC }) {
            totalCount
            pageInfo {
                hasNextPage
                endCursor
            }
            edges {
                node {
                    name
                    id
                    page {
                        slug
                    }
                }
            }
        }
    }
`

export const GET_SPEAKER_LIST = gql`
    query GetSpeakers(
        $first: Int
        $afterCursor: Cursor
        $eventId: ObjectID
        $sort: SortDirection
        $search: String
    ) {
        speakers(
            first: $first
            after: $afterCursor
            filter: { event: $eventId }
            orderBy: { firstName: $sort }
            search: $search
        ) {
            totalCount
            pageInfo {
                hasNextPage
                endCursor
            }
            edges {
                node {
                    ...SpeakerData
                }
            }
        }
    }

    ${fragments.SpeakerData}
`

export const GET_SPEAKER_LIST_ORDERABLE = gql`
    query GetSpeakers(
        $first: Int
        $afterCursor: Cursor
        $eventId: ObjectID
        $orderBy: SpeakerOrderBy
        $search: String
        $tags: Filter
        $featured: Filter
    ) {
        speakers(
            first: $first
            after: $afterCursor
            filter: { event: $eventId, tags: $tags, featured: $featured }
            orderBy: $orderBy
            search: $search
        ) {
            totalCount
            pageInfo {
                hasNextPage
                endCursor
            }
            edges {
                node {
                    ...SpeakerData
                }
            }
        }
    }

    ${fragments.SpeakerData}
`

export const GET_SPEAKER_DASHBOARD_TALK = gql`
    query GetSpeakers($first: Int, $afterCursor: Cursor, $talk: ObjectID) {
        speakers(first: $first, after: $afterCursor, filter: { talk: $talk }) {
            totalCount
            pageInfo {
                hasNextPage
                endCursor
            }
            edges {
                node {
                    id
                    name
                    image {
                        ...MediaData
                    }
                    company {
                        name
                    }
                    office
                }
            }
        }
    }
    ${fragments.MediaData}
`

export const GET_SPEAKER_USER = gql`
    query GetSpeakerUser($user: ObjectID!) {
        speakers(
            first: 1
            filter: { user: $user }
            orderBy: { updatedAt: DESC }
        ) {
            totalCount
            pageInfo {
                hasNextPage
                endCursor
            }
            edges {
                node {
                    ...SpeakerData
                }
            }
        }
    }
    ${fragments.SpeakerData}
`

export const GET_SPEAKER = gql`
    query GetSpeaker($id: ObjectID!) {
        speaker(id: $id) {
            ...SpeakerData
        }
    }

    ${fragments.SpeakerData}
`

export const GET_SPEAKER_TAGS = gql`
    query GetSpeakerTags($eventId: ObjectID!) {
        speakerTags(event: $eventId)
    }
`
export const GET_SPEAKER_SELECT_LIST = gql`
    query GetSpeakersSelectList(
        $first: Int
        $afterCursor: Cursor
        $eventId: ObjectID
        $sort: SortDirection
        $featured: Filter
    ) {
        speakers(
            first: $first
            after: $afterCursor
            filter: { event: $eventId, featured: $featured }
            orderBy: { firstName: $sort }
        ) {
            totalCount
            pageInfo {
                hasNextPage
                endCursor
            }
            edges {
                node {
                    value: id
                    label: name
                }
            }
        }
    }
`

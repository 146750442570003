import { useQuery } from '@apollo/client'
import { ButtonReturn } from '@cms/events/components/Buttons'
import Content from '@cms/core/components/Content'
import { CreateModalRoutes } from '@cms/core/components/Crud'
import Reactions from '@cms/events/components/Dashboard/Reactions'
import Loader from '@cms/core/components/Loader'
import {
    GET_EVENT,
    GET_EVENT_STREAMING_TOKENS
} from '@cms/events/graphql/queries/event'
import { GET_TALK } from '@cms/events/graphql/queries/talk'
import { useCreation } from '@umijs/hooks'
import { Button, Col, Layout, Row, Typography } from 'antd'
import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import NotificationTalkModal from '../../Notifications/NotificationTalkModal'
import TalkAdvertising from './Advertising'
import Avaliation from './Avaliation'
import Chat from './Chat'
import DisplayTime from './Infos/DisplayTime'
import PeakTime from './Infos/PeakTime'
import TalkViews from './Infos/TalkViews'
import TotalViews from './Infos/TotalViews'
import Player from './Player'
import Speakers from './Speakers'
import { InfoCard, TabPane, Tabs, WrapperButtons, WrapperInfo } from './styles'
import SurveyModal from './SurveyModal'
import SurveyReport from './SurveyReport'
import Surveys from './Surveys'

const { Text } = Typography

const ModalContent = ({ eventId, type, params }) => {
    const { id } = useParams()
    const [token, setToken] = useState()

    const { data: eventData, loading } = useQuery(GET_EVENT, {
        variables: { id: eventId }
    })

    const { data: TalkData, refetch } = useQuery(GET_TALK, {
        variables: { id },
        fetchPolicy: 'no-cache',
        returnPartialData: true
    })

    const { data: tokensData } = useQuery(GET_EVENT_STREAMING_TOKENS, {
        variables: {
            event: eventId
        }
    })

    useEffect(() => {
        if (tokensData?.eventStreamingTokens) {
            const { token } = tokensData?.eventStreamingTokens
            setToken(token)
        }
    }, [tokensData])

    const path = `/evento/${eventId}/palestras/${id}/painel`

    const ModalSurveyRoute = useCreation(() => {
        const path = `/evento/${eventId}/palestras/${id}/painel/survey`
        return CreateModalRoutes(SurveyModal, path, { id })
    }, [eventId])

    const ModalTalkNotificationRoute = useCreation(() => {
        const path = `/evento/${eventId}/palestras/${id}/painel/publicidade/notificacoes`
        return CreateModalRoutes(NotificationTalkModal, path, {
            talkId: id,
            eventId
        })
    }, [eventId])

    const ModalRoutes = useCreation(() => {
        const Routers = CreateModalRoutes(Surveys, path, {
            eventId
        })
        return () => (
            <>
                <Routers.Custom
                    path={`${path}/survey`}
                    render={() => (
                        <Surveys eventId={eventId} path={path} talkId={id} />
                    )}
                />
                <Routers.Custom
                    path={`${path}/publicidade`}
                    render={() => (
                        <TalkAdvertising
                            eventId={eventId}
                            path={path}
                            talk={id}
                        />
                    )}
                />
                <Routers.Custom
                    path={`${path}/survey/:surveyid/report`}
                    render={() => (
                        <SurveyReport eventId={eventId} path={path} />
                    )}
                />
            </>
        )
    }, [eventId])

    if (!eventData) return null

    return (
        <>
            <ModalSurveyRoute />
            <ModalRoutes />
            <ModalTalkNotificationRoute />
            <Layout
                style={{
                    minHeight: 'fit-content',
                    width: '100%',
                    overflow: 'hidden',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between'
                }}
            >
                {!loading ? (
                    <Content
                        style={{
                            padding: 0,
                            backgroundColor: 'rgba(255, 255, 255, 0.0)',
                            marginBottom: '0'
                        }}
                    >
                        <Tabs
                            defaultActiveKey="1"
                            scrollY={500}
                            tabBarExtraContent={
                                <ButtonReturn />
                            }
                        >
                            <TabPane tab={TalkData?.talk.title} key="1">
                                <Row gutter={[24, 24]}>
                                    <Col
                                        span={14}
                                        style={{
                                            justifyContent: 'space-between',
                                            display: 'flex',
                                            minWidth: '100%',
                                            alignItems: 'center'
                                        }}
                                    >
                                        <WrapperButtons>
                                            <Button>
                                                <Link to={path + '/survey'}>
                                                    Gerenciar Enquete
                                                </Link>
                                            </Button>
                                            <Button>
                                                <Link
                                                    to={path + '/publicidade'}
                                                >
                                                    Publicidade
                                                </Link>
                                            </Button>
                                        </WrapperButtons>
                                        {TalkData?.talk && (
                                            <WrapperInfo>
                                                <InfoCard>
                                                    <TotalViews
                                                        talk={TalkData?.talk.id}
                                                        eventId={eventId}
                                                    />
                                                </InfoCard>
                                                <InfoCard>
                                                    <DisplayTime
                                                        talk={TalkData?.talk.id}
                                                        eventId={eventId}
                                                    />
                                                </InfoCard>
                                                <InfoCard>
                                                    <PeakTime
                                                        talk={TalkData?.talk.id}
                                                        eventId={eventId}
                                                    />
                                                </InfoCard>
                                                <InfoCard
                                                    style={{ marginRight: '0' }}
                                                >
                                                    <TalkViews
                                                        talk={TalkData?.talk.id}
                                                        event={eventId}
                                                    />
                                                </InfoCard>
                                            </WrapperInfo>
                                        )}
                                    </Col>
                                </Row>

                                <Row
                                    gutter={[32, 32]}
                                    style={{ marginTop: '25px' }}
                                >
                                    <Col span={7}>
                                        {token && (
                                            <Chat talk={id} token={token} />
                                        )}
                                    </Col>
                                    {TalkData?.talk && (
                                        <>
                                            <Col span={11}>
                                                <Player
                                                    talk={TalkData?.talk}
                                                    eventId={eventId}
                                                    refetch={refetch}
                                                    type={type}
                                                />
                                            </Col>
                                            <Col span={6}>
                                                <Row>
                                                    <Avaliation />
                                                </Row>
                                                <Row
                                                    style={{ margin: '20px 0' }}
                                                >
                                                    <Reactions
                                                        reactions={
                                                            TalkData?.talk
                                                                .reactions
                                                        }
                                                    />
                                                </Row>
                                                <Row>
                                                    <Speakers
                                                        speakers={
                                                            TalkData?.talk
                                                                .speakers.edges
                                                        }
                                                    />
                                                </Row>
                                            </Col>
                                        </>
                                    )}
                                </Row>
                            </TabPane>
                        </Tabs>
                    </Content>
                ) : (
                    <Loader />
                )}
            </Layout>
        </>
    )
}

export default ModalContent

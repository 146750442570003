import React, { useState, useEffect } from 'react'
import { useLocation, Link, useParams } from 'react-router-dom'
import { Affix, Col, Button, Row, Space, message } from 'antd'
import { useQuery, useMutation } from '@apollo/client'

import { UPDATE_SITE } from '@cms/events/graphql/mutations/site'
import { ButtonLink } from '@cms/events/components/Buttons'

import { Navigation, Wrapper } from './styles'
import { ROUTES } from '../../constants/routes'

const Nav = () => {
    const { eventId } = useParams()
    const { pathname } = useLocation()
    const [loading, setLoading] = useState(false)

    const handlePublishedWidgets = async () => {
        const { id } = config
        setLoading(true)

        try {
            // await updateSite({
            //     variables: {
            //         id,
            //         data: site
            //     }
            // })
            message.success('Site publicado com sucesso!')
        } catch (error) {
            message.error(error.message)
        }

        setLoading(false)
    }

    const mountPath = (path) => {
        return path.replace(':eventId', eventId)
    }

    return (
        <Wrapper>
            <Affix offsetTop={0}>
                <Row className="header" justify="space-between">
                    <Col>
                        <Navigation>
                            <Col>
                                {ROUTES.map(({ title, path }) => (
                                    <Link
                                        key={title}
                                        to={() => mountPath(path)}
                                        className={
                                            pathname === mountPath(path)
                                                ? 'active-nav'
                                                : ''
                                        }
                                    >
                                        {title}
                                    </Link>
                                ))}
                            </Col>
                        </Navigation>
                    </Col>
                    <Col>
                        {/* <Button
                            type="primary"
                            loading={loading}
                            onClick={handlePublishedWidgets}
                        >
                            Salvar
                        </Button> */}
                    </Col>
                </Row>
            </Affix>
        </Wrapper>
    )
}

export default Nav

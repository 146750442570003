import React from 'react'
import { Input, Row, Select } from 'antd'
import { debounce } from 'lodash'
import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons'
import { CrudLisSearchRow } from './styles'

const { Option } = Select

export default function CrudListSearch({
    refetch,
    orderByFields,
    orderField,
    handledOrderBy,
    sort,
    handledSort
}) {
    const refetchDebounce = debounce(refetch, 300)

    const handleSearch = (e) => {
        const keycode = e.keyCode ? e.keyCode : e.which
        const value = e.target.value
        let _refetch = refetchDebounce

        if (keycode == '13') {
            _refetch = refetch
        }

        _refetch({
            search: value
        })
    }

    return (
        <CrudLisSearchRow key="list-actions" align={'middle'}>
            <Input.Search
                className="input-search"
                style={{ width: 300 }}
                type="text"
                placeholder={'Buscar...'}
                onChange={handleSearch}
                allowClear
            />
            {orderByFields && (
                <Select
                    style={{
                        marginRight: '10px',
                        marginLeft: '10px'
                    }}
                    value={orderField}
                    onChange={handledOrderBy}
                >
                    {orderByFields.map((item, key) => {
                        return (
                            <Option
                                key={key}
                                value={item.value}
                                title={item.label}
                            >
                                {item.label}
                            </Option>
                        )
                    })}
                </Select>
            )}
            {sort === 'DESC' ? (
                <ArrowDownOutlined onClick={() => handledSort('ASC')} />
            ) : (
                <ArrowUpOutlined onClick={() => handledSort('DESC')} />
            )}
        </CrudLisSearchRow>
    )
}

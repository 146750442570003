import { gql } from '@apollo/client'
import * as fragments from '../fragments'

export const GET_TICKET_LIST_ORDENABLE = gql`
    query GetTicket(
        $first: Int
        $afterCursor: Cursor
        $orderBy: TicketOrderBy
        $eventId: ObjectID!
        $search: String
    ) {
        tickets(
            first: $first
            after: $afterCursor
            orderBy: $orderBy
            search: $search
            filter: { event: $eventId }
        ) {
            totalCount
            pageInfo {
                hasNextPage
                endCursor
            }
            edges {
                node {
                    ...TicketData
                }
            }
        }
    }

    ${fragments.TicketData}
`

export const GET_TICKETS_LIST = gql`
    query GetTicketList(
        $eventId: ObjectID!
        $first: Int
        $afterCursor: Cursor
    ) {
        tickets(
            filter: { event: $eventId }
            first: $first
            after: $afterCursor
        ) {
            totalCount
            pageInfo {
                hasNextPage
                endCursor
            }
            edges {
                node {
                    ...TicketData
                }
            }
        }
    }

    ${fragments.TicketData}
`

export const GET_TICKET_OWNERSHIPS_LIST = gql`
    query GetTicketOwnershipsList($eventId: ObjectID!, $userId: ObjectID!) {
        ticketOwnerships(
            filter: { event: $eventId, user: $userId }
            orderBy: { createdAt: DESC }
        ) {
            edges {
                node {
                    ...TicketOwnershipsData
                }
            }
        }
    }

    ${fragments.TicketOwnershipsData}
`

export const GET_TICKET = gql`
    query GetTicket($id: ObjectID!) {
        ticket(id: $id) {
            ...TicketData
        }
    }

    ${fragments.TicketData}
`

import React from 'react'

import { StreamingProvider, useStreamingContext } from './context'
import { BASE_URL, ROUTES } from './constants/routes'
import { ThemeBuilderProvider } from '@cms/core/components/ThemeBuilder'
import { ThemeBuilderContent } from '@cms/core/components/ThemeBuilder/components/Content'

const StreamingConfigContent = () => {
    const { save } = useStreamingContext()

    return <ThemeBuilderContent save={save} />
}

const StreamingConfigScreen = () => {
    return (
        <ThemeBuilderProvider
            routesConfig={{ baseUrl: BASE_URL, routes: ROUTES }}
        >
            <StreamingProvider>
                <StreamingConfigContent />
            </StreamingProvider>
        </ThemeBuilderProvider>
    )
}

export default StreamingConfigScreen

import React from 'react'
import { useParams } from 'react-router-dom'
import { useQuery, useMutation } from '@apollo/client'
import { Progress } from 'antd'

import { useCreation } from '@umijs/hooks'

import { GET_REPORT_LIST_ORDENABLE } from '@cms/events/graphql/queries/reports'
import { DELETE_REPORT } from '@cms/events/graphql/mutations/reports'
import { removeFromList } from '@cms/core/graphql/utils'

import { CrudList, CreateModalRoutes } from '@cms/core/components/Crud'
import { ButtonDefault } from '@cms/events/components/Buttons'

import { ReloadOutlined } from '@ant-design/icons'

import ReportModal from './ReportsModal'
import { typesReport } from './constants'

const statusReport = [
    {
        name: 'Pendente',
        value: 'PENDING'
    },
    {
        name: 'Finalizado',
        value: 'DONE'
    },
    {
        name: 'Desatualizado',
        value: 'OUTDATED'
    }
]

const columns = [
    {
        title: 'Evento',
        dataIndex: ['node', 'event', 'name'],
        render: (_, obj) => {
            const label = typesReport.find((f) => f.value === obj.node.type)
            return <strong>{label?.name || obj.node.type}</strong>
        }
    },
    {
        title: 'Status',
        dataIndex: [''],
        render: (_, obj) => {
            const label = statusReport.find((f) => f.value === obj.node.status)
            return <span>{label?.name}</span>
        }
    },
    {
        title: 'Action',
        dataIndex: [''],
        render: (_, obj) => {
            const text =
                obj.node.downloadLink !== null ? 'Download' : 'Processando'
            const disabled = obj.node.downloadLink !== null ? false : true

            if (obj.node.downloadLink === null) {
                return <Progress percent={obj.node.progress} />
            }

            return (
                <ButtonDefault
                    type="primary"
                    onClick={() => openURL(obj.node.downloadLink)}
                    disabled={disabled}
                >
                    {text}
                </ButtonDefault>
            )
        }
    }
]

const orderByFields = [{ label: 'Data criação', value: 'createdAt' }]

const openURL = (url) => {
    window.open(url, '_blank')
}

/**
 * Página de relatórios
 */
const Reports = () => {
    const { eventId } = useParams()

    const path = `/evento/${eventId}/reports`

    const variables = { first: 25, eventId, orderBy: { createdAt: 'DESC' } }

    const query = useQuery(GET_REPORT_LIST_ORDENABLE, {
        variables,
        pollInterval: 3000,
        notifyOnNetworkStatusChange: false
    })

    const [deleteData] = useMutation(DELETE_REPORT, {
        update: removeFromList({ list: 'reports', Type: 'Report' })
    })

    const ModalRoutes = useCreation(() => {
        return CreateModalRoutes(ReportModal, path, { eventId })
    }, [eventId])

    if (query.error) return <div>Error: {query.error}</div>

    return (
        <>
            <ModalRoutes />
            <CrudList
                header={{
                    title: 'Relatórios',
                    buttons: [
                        {
                            children: <ReloadOutlined />,
                            type: 'primary',
                            action: () => {
                                query.refetch()
                            }
                        },
                        {
                            children: 'Novo Relatório',
                            type: 'primary',
                            link: path + '/adicionar'
                        }
                    ]
                }}
                search={true}
                columns={columns}
                queryName={'reports'}
                onDelete={deleteData}
                orderByFields={orderByFields}
                hideAction="edit"
                {...query}
            />
        </>
    )
}

export default Reports

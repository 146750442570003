import React from 'react'

import { Row, Col, Switch, Avatar, Form, Typography } from 'antd'

import { EditOutlined, CheckOutlined, CloseOutlined } from '@ant-design/icons'

import { CardWrapper, CardLogo } from './styles'

const { Title, Paragraph } = Typography

import * as _connectors from '../connectores/configs'

const CardItem = ({
    integration,
    onChangeActive,
    onEdit,
    hiddenTitle = false,
    hiddenName = false
}) => {
    const { type, name, active, config } = integration
    return (
        <CardWrapper>
            <Row align="stretch" style={{ height: '100%' }}>
                <Col span={24}>
                    <Row>
                        <Col span={18}>
                            {_connectors[type]?.logo && (
                                <CardLogo src={_connectors[type].logo} />
                            )}
                            {!hiddenName && <Title level={3}>{name}</Title>}
                            <Paragraph>{_connectors[type].title}</Paragraph>
                        </Col>
                        <Col span={6}>
                            <Row align="middle" justify="end">
                                <Switch
                                    onChange={onChangeActive}
                                    checked={active === true}
                                    checkedChildren={<CheckOutlined />}
                                    unCheckedChildren={<CloseOutlined />}
                                />
                            </Row>
                        </Col>
                    </Row>
                </Col>
                <Col span={24}>
                    <Row gutter={24} align="middle">
                        <Col span={24}>
                            {_connectors[type].editable ? (
                                <Row align="middle" justify="end">
                                    <EditOutlined
                                        disabled={!_connectors[type].editable}
                                        onClick={onEdit}
                                        style={{
                                            marginRight: '10px'
                                        }}
                                    />
                                </Row>
                            ) : null}
                        </Col>
                    </Row>
                </Col>
            </Row>
        </CardWrapper>
    )
}

export default CardItem


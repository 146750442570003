import React from 'react'
import dayjs from 'dayjs'
import { Space, Typography } from 'antd'
import _get from 'lodash/get'
import { useParams } from 'react-router-dom'
import { useCreation } from '@umijs/hooks'
import { useQuery } from '@apollo/client'
import {
    CrudColumns,
    CrudList,
    CreateModalRoutes
} from '@cms/core/components/Crud'
import { ButtonLink } from '@cms/events/components/Buttons'
import { GET_FORM_DATA_LIST_ORDERABLE } from '@cms/events/graphql/queries/formData'
import { GET_EVENT_FORMS } from '@cms/events/graphql/queries/event'

// import  SubscritionModal from './ModalFull'

const { Text } = Typography

const FormDataList = (props) => {
    const { eventId, uuid } = useParams()

    const { data: formData } = useQuery(GET_EVENT_FORMS, {
        variables: { id: eventId }
    })

    const query = useQuery(GET_FORM_DATA_LIST_ORDERABLE, {
        variables: { first: 25, eventId, uuid, orderBy: { createdAt: 'DESC' } }
    })

    const eventForms = formData?.event.forms

    const form = eventForms?.find((o) => o.uuid === uuid)

    if (!form) return null

    const orderByFields = [{ label: 'Data criação', value: 'createdAt' }]

    if (query.error) return <div>Error</div>

    const columns = []

    form.fields?.forEach((field) => {
        columns.push({
            title: field.label,
            dataIndex: [''],
            width: 200,
            render: (_, obj) => {
                const value = _get(obj.node.data, field.name, '----')
                return (
                    <CrudColumns onlyText title={field.label} text={[value]} />
                )
            }
        })
    })

    columns.push({
        title: 'Data de criação',
        dataIndex: [''],
        width: 200,
        render: (_, obj) => {
            const value = dayjs(new Date(obj.node.createdAt)).format(
                'DD MMM YYYY'
            )
            return (
                <CrudColumns onlyText title="Data de criação" text={[value]} />
            )
        }
    })

    return (
        <>
            <CrudList
                header={{
                    title: 'Dados do formulário: ' + form.name
                }}
                search={true}
                columns={columns}
                queryName={'formDatas'}
                hideAction={'all'}
                orderByFields={orderByFields}
                actionFixed={true}
                {...query}
            />
        </>
    )
}

export default FormDataList

import React from "react";
import { useQuery, gql } from '@apollo/client'

const TalkViews = ({event, talk}) => {
    const { data, loading, error } = useQuery(
        gql`
            query ($event: ObjectID!, $talk: ObjectID) {
                uniqueUsers(event: $event, talk: $talk)
            }
        `,
        { variables: { talk, event } }
    )
    
    if (loading || error) return null

    return (
        <p>
            {data?.uniqueUsers || 0}
            <span>Usuários únicos</span>
        </p>
    )
}

export default TalkViews
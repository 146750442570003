import { IAppModule } from '@cms/core/types/app'
import { sidebarIcons } from '@cms/core/components/RemixIcons'

export const WatchModules: IAppModule[] = [
    {
        name: 'Usuários',
        icon: sidebarIcons.users,
        module: 'watch.users',
        type: 'main',
        children: [
            {
                name: 'Usuários',
                alias: true,
                path: '/usuarios',
                module: 'watch.users'
            }
        ]
    },
    {
        name: 'Planos',
        icon: sidebarIcons.payments,
        module: 'watch.plans',
        type: 'main',
        children: [
            {
                alias: true,
                name: 'Planos',
                path: '/planos',
                module: 'watch.plans'
            }
        ]
    },
    {
        name: 'Especialistas',
        icon: sidebarIcons.users,
        module: 'watch.experts',
        type: 'main',
        children: [
            {
                name: 'Especialistas',
                alias: true,
                path: '/especialistas',
                module: 'watch.experts'
            }
        ]
    },
    {
        name: 'Conteúdos',
        icon: sidebarIcons.content,
        module: 'watch.content',
        type: 'main',
        children: [
            {
                name: 'Vídeos',
                path: '/conteudo/videos',
                module: 'watch.content.videos'
            },
            {
                name: 'Categorias',
                path: '/conteudo/categorias',
                module: 'watch.content.categories'
            }
        ]
    },
    {
        name: 'Cursos',
        icon: sidebarIcons.courses,
        module: 'watch.course',
        type: 'main',
        children: [
            {
                name: 'Cursos',
                path: '/cursos',
                excludePaths: ['/cursos/videos(.*)'],
                module: 'watch.course.list'
            },
            {
                name: 'Vídeos',
                path: '/cursos/videos',
                module: 'watch.course.videos'
            }
        ]
    },
    {
        name: 'Integrações',
        icon: sidebarIcons.integrations,
        module: 'watch.integrations',
        type: 'main',
        children: [
            {
                alias: true,
                name: 'Players',
                path: `/integracoes/players`,
                module: 'watch.integrations.players'
            }
        ]
    },
    {
        name: 'Configurações',
        icon: sidebarIcons.settings,
        module: 'watch.config',
        type: 'footer',
        children: [
            {
                name: 'Configurações',
                path: '/config',
                module: 'watch.config.configs'
            },
            {
                name: 'Domínio',
                path: '/dominio',
                module: 'watch.config.domain'
            }
        ]
    }
]

import React, { useState } from 'react'
import {
    message,
    Form,
    Input,
    InputNumber,
    Checkbox,
    Select,
    Tag,
    Divider,
    Button,
    Row,
    Col,
    Typography
} from 'antd'
import { useSelector } from 'react-redux'
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'
import { useApolloClient, useQuery } from '@apollo/client'

import SelectSearch from '@cms/core/components/SelectSearch'
import MediaUploader from '@cms/core/components/MediaUploader/graphql'
import {
    CrudModal,
    useCrudForm,
    useCrudSelect
} from '@cms/core/components/Crud'

import Card from '@cms/core/components/Card'

import { addToList } from '@cms/core/graphql/utils'
import {
    GET_BRAND,
    GET_BRAND_SPONSOR,
    GET_BRAND_TAGS
} from '@cms/events/graphql/queries/brand'
import { CREATE_BRAND, UPDATE_BRAND } from '@cms/events/graphql/mutations/brand'
import {
    GET_USERS_BY_ROLE,
    GET_SPONSORS_LIST
} from '@cms/events/graphql/queries/user'
import {
    GET_BRAND_PLAN,
    GET_BRAND_PLANS_LIST
} from '@cms/events/graphql/queries/brandPlan'

import { displayIf, getObjectDiff } from '@cms/core/utils'
import { identity, merge, pickBy } from 'lodash'

const { Paragraph } = Typography

const modeOptions = [
    {
        name: 'Pequeno',
        value: 'small'
    },
    {
        name: 'Padrão',
        value: 'normal'
    },
    {
        name: 'Grande',
        value: 'large'
    },
    {
        name: 'Link Externo',
        value: 'external'
    }
]

const getData = (data) => {
    return {
        ...data,
        sponsor: data.sponsor?.id,
        plan: data.plan?.id,
        chiefs: data.chiefs.map((chief) => chief.id),
        _chiefs: data.chiefs.map((chief) => ({ node: chief }))
    }
}

const BrandsModal = ({ params, isModal = true, ...props }) => {
    const [hostType, setHostType] = useState('DIGITAL')
    const { eventId } = params
    const [standType, setStandType] = useState()
    const isSponsor = useSelector((state) => state.user.role === 'sponsor')
    const client = useApolloClient()

    const { data: TagsData } = useQuery(GET_BRAND_TAGS, {
        variables: { eventId },
        fetchPolicy: 'no-cache'
    })

    const queryPlans = useQuery(GET_BRAND_PLANS_LIST, {
        variables: { first: 9999 },
        fetchPolicy: 'cache-and-network'
    })

    const [PlansOptions, planSelectProps] = useCrudSelect(queryPlans, {
        queryName: 'brandPlans',
        includeEmpty: true
    })

    const [{ form, isEdit, getError, formUpdate }, modalProps] = useCrudForm({
        relateds: [],
        getData: async (id) => {
            if (!id) return

            const { data } = await client.query({
                query: GET_BRAND,
                variables: { id }
            })

            setHostType(data.brand.plan?.hostType || 'DIGITAL')
            setStandType(data.brand.talkStand?.type || 'email')
            form.validateFields([['talkStand', 'type']])

            return getData(data.brand)
        },
        createData: async (formData) => {
            await client.mutate({
                mutation: CREATE_BRAND,
                update: addToList({ list: 'brands', Type: 'Brand' }),
                variables: { ...formData, event: eventId }
            })

            message.success('Patrocinador cadastrado com sucesso!')
        },
        updateData: async (id, formData) => {
            await client.mutate({
                mutation: UPDATE_BRAND,
                variables: {
                    ...formData,
                    id,
                    event: eventId
                }
            })

            message.success('Patrocinador atualizado com sucesso!')
        }
    })

    const handleChangeStandType = (e) => {
        setStandType(e)
    }

    const SelectSponsor = () => {
        const querySponsors = useQuery(GET_SPONSORS_LIST, {
            variables: { first: 9999 },
            fetchPolicy: 'cache-and-network'
        })

        const [SponsorsOptions, sponsorSelectProps] = useCrudSelect(
            querySponsors,
            {
                queryName: 'users',
                includeEmpty: true
            }
        )

        const handleSponsorSelect = async (sponsor) => {
            if (!isEdit) {
                const { data } = await client.query({
                    query: GET_BRAND_SPONSOR,
                    variables: { sponsor }
                })

                const brands = data?.brands?.edges

                if (Array.isArray(brands) && brands[0]) {
                    const node = brands[0].node
                    const mergeData = merge(
                        {},
                        getData(node),
                        pickBy(form.getFieldsValue(), identity)
                    )
                    formUpdate(mergeData)
                }
            }
        }

        return (
            <Form.Item
                label="Usuário de atendimento do patrocinador"
                name={'sponsor'}
                {...getError('sponsor')}
            >
                <SelectSearch
                    {...sponsorSelectProps}
                    mode={null}
                    onSelect={handleSponsorSelect}
                >
                    {SponsorsOptions({
                        key: 'node.id',
                        label: 'node.name'
                    })}
                </SelectSearch>
            </Form.Item>
        )
    }

    const SelectChiefOrSpeaker = () => {
        const querySponsors = useQuery(GET_USERS_BY_ROLE, {
            variables: { first: 9999, role: ['chief', 'speaker'] },
            fetchPolicy: 'cache-and-network'
        })

        const [options, selectProps] = useCrudSelect(querySponsors, {
            queryName: 'users',
            additionalValues: form.getFieldValue('_chiefs')
        })

        return (
            <Form.Item
                label="Executivos"
                name={'chiefs'}
                {...getError('chiefs')}
            >
                <SelectSearch {...selectProps}>
                    {options({
                        key: 'node.id',
                        label: 'node.name'
                    })}
                </SelectSearch>
            </Form.Item>
        )
    }

    const typeStandSelector = (
        <Form.Item name={['talkStand', 'type']} noStyle>
            <Select
                onSelect={handleChangeStandType}
                style={{
                    width: 100
                }}
            >
                <Select.Option value="email">E-mail</Select.Option>
                <Select.Option value="link">Link</Select.Option>
            </Select>
        </Form.Item>
    )

    const [socialMediaTypes] = useState([
        'Linkedin',
        'Facebook',
        'Twitter',
        'Instagram',
        'TikTok'
    ])

    const handlePlansSelect = async (id) => {
        if (!isEdit) {
            const { data } = await client.query({
                query: GET_BRAND_PLAN,
                variables: { id }
            })

            setHostType(data?.brandPlan?.hostType)
        }
    }

    const WrapperForm = () => {
        return (
            <Form
                layout={'vertical'}
                form={form}
                initialValues={{ talkStand: { type: 'email' } }}
            >
                {!isSponsor && (
                    <Form.Item
                        label="Plano"
                        name={'plan'}
                        rules={[
                            { required: true, message: 'Plano é obrigatório!' }
                        ]}
                        {...getError('plan')}
                    >
                        <SelectSearch
                            onSelect={handlePlansSelect}
                            {...planSelectProps}
                            mode={null}
                        >
                            {PlansOptions({
                                key: 'node.id',
                                label: 'node.name',
                                groupBy: {
                                    key: 'node.hostType',
                                    groups: {
                                        DIGITAL: 'Digital',
                                        HYBRID: 'Híbrido',
                                        ONSTAGE: 'Presencial'
                                    }
                                }
                            })}
                        </SelectSearch>
                    </Form.Item>
                )}

                <Form.Item
                    label="Nome"
                    name="name"
                    rules={[{ required: true, message: 'Nome é obrigatório!' }]}
                    {...getError('name')}
                >
                    <Input />
                </Form.Item>

                <Form.Item name="description" label="Descrição">
                    <Input.TextArea placeholder="Descrição do patrocinador" />
                </Form.Item>

                <Form.Item name="aboutUs" label="Sobre Nós">
                    <Input.TextArea />
                </Form.Item>

                <Form.Item name={['page', 'enabled']} valuePropName="checked">
                    <Checkbox>Habilitar página de patrocinador</Checkbox>
                </Form.Item>

                <Form.Item noStyle shouldUpdate>
                    {({ getFieldValue }) => {
                        const sponsorPage = getFieldValue(['page', 'enabled'])

                        if (!sponsorPage) {
                            return null
                        }

                        const hanleOnKeyPress = (e) => {
                            const regex = new RegExp(
                                /^[A-Za-z0-9_-]+(?:-[A-Za-z0-9_-]+)*$/
                            )
                            const str = String.fromCharCode(
                                !e.charCode ? e.which : e.charCode
                            )
                            if (regex.test(str)) {
                                return true
                            }

                            e.preventDefault()
                            return false
                        }

                        return (
                            <Card title="Página do patrocinador">
                                <Row gutter={24} align="middle">
                                    <Col span={24}>
                                        <Form.Item
                                            label="Título"
                                            name={['page', 'title']}
                                            rules={[
                                                {
                                                    required: !!sponsorPage,
                                                    message:
                                                        'Título é obrigatório!'
                                                }
                                            ]}
                                        >
                                            <Input />
                                        </Form.Item>
                                    </Col>

                                    <Col span={8}>
                                        <Form.Item
                                            label="Foto de destaque"
                                            name={['page', 'image']}
                                            rules={[
                                                {
                                                    required: !!sponsorPage,
                                                    message:
                                                        'Imagem é obrigatório!'
                                                }
                                            ]}
                                        >
                                            <MediaUploader
                                                options={{
                                                    optimize: {
                                                        resize: {
                                                            width: 500,
                                                            height: 500
                                                        },
                                                        quality: 80
                                                    }
                                                }}
                                                type="image"
                                            />
                                        </Form.Item>
                                    </Col>

                                    <Col span={16}>
                                        <Form.Item
                                            label="Link personalizado"
                                            name={['page', 'slug']}
                                            rules={[
                                                {
                                                    required: !!sponsorPage,
                                                    message:
                                                        'Link personalizado é obrigatório!'
                                                }
                                            ]}
                                        >
                                            <Input
                                                onKeyPress={hanleOnKeyPress}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Card>
                        )
                    }}
                </Form.Item>
                <Card title="Contato do patrocinador">
                    {!isSponsor && <SelectSponsor />}
                    {!isSponsor && <SelectChiefOrSpeaker />}
                </Card>

                <Card title="Para a pagina de inscrição">
                    <Form.Item name="site" label="Site">
                        <Input />
                    </Form.Item>
                    <Form.Item name="email" label="Email">
                        <Input type="email" />
                    </Form.Item>

                    <Form.Item name="contact" label="Contato">
                        <Input.TextArea placeholder="Informações de Contato" />
                    </Form.Item>

                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item
                                label="Logo"
                                name="logo"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Logo é obrigatório!'
                                    }
                                ]}
                            >
                                <MediaUploader type="svg" />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item label="Banner" name="image">
                                <MediaUploader type="image" />
                            </Form.Item>
                        </Col>
                    </Row>
                </Card>

                <Card title="Redes sociais">
                    <Form.List name="social">
                        {(fields, { add, remove }) => (
                            <>
                                {fields.map((field, index) => (
                                    <div key={`social.${index}`}>
                                        <Row
                                            className="actionsBox"
                                            gutter={24}
                                            align="middle"
                                        >
                                            <Col span={24}>
                                                <Paragraph>
                                                    Rede {index + 1}
                                                </Paragraph>
                                            </Col>
                                            <Col span={8}>
                                                <Form.Item
                                                    label="Plataforma"
                                                    name={[field.name, 'type']}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message:
                                                                'Plataforma é obrigatório!'
                                                        }
                                                    ]}
                                                >
                                                    <Select
                                                        showSearch
                                                        filterOption={(
                                                            input,
                                                            option
                                                        ) =>
                                                            option.children
                                                                .toLowerCase()
                                                                .indexOf(
                                                                    input.toLowerCase()
                                                                ) >= 0
                                                        }
                                                    >
                                                        {socialMediaTypes.map(
                                                            (o) => (
                                                                <Option key={o}>
                                                                    {o}
                                                                </Option>
                                                            )
                                                        )}
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                            <Col span={14}>
                                                <Form.Item
                                                    {...field}
                                                    label="Url"
                                                    name={[field.name, 'url']}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message:
                                                                'Url é obrigatório!'
                                                        }
                                                    ]}
                                                >
                                                    <Input />
                                                </Form.Item>
                                            </Col>
                                            <Col span={2}>
                                                <MinusCircleOutlined
                                                    style={{ marginTop: 20 }}
                                                    onClick={() =>
                                                        remove(field.name)
                                                    }
                                                />
                                            </Col>
                                        </Row>
                                    </div>
                                ))}
                                <Form.Item>
                                    <Button
                                        type="dashed"
                                        onClick={() => {
                                            add({})
                                        }}
                                        style={{ width: '100%' }}
                                    >
                                        <PlusOutlined /> Adicionar
                                    </Button>
                                </Form.Item>
                            </>
                        )}
                    </Form.List>
                </Card>

                <Card
                    style={displayIf(hostType, 'ONSTAGE')}
                    title="Botões e ações do stand do patrocinador"
                >
                    <Form.List name="actions">
                        {(fields, { add, remove }) => (
                            <>
                                {fields.map((field, index) => (
                                    <div key={`actions.${index}`}>
                                        <Row
                                            className="actionsBox"
                                            gutter={24}
                                            align="middle"
                                        >
                                            <Col span={22}>
                                                <Paragraph>
                                                    Ação {index + 1}
                                                </Paragraph>
                                                <Row gutter={24}>
                                                    <Col span={24}>
                                                        <Form.Item
                                                            label="Nome"
                                                            name={[
                                                                field.name,
                                                                'name'
                                                            ]}
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message:
                                                                        'Nome é obrigatório!'
                                                                }
                                                            ]}
                                                        >
                                                            <Input />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={24}>
                                                        <Form.Item
                                                            {...field}
                                                            label="Url"
                                                            name={[
                                                                field.name,
                                                                'url'
                                                            ]}
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message:
                                                                        'Url é obrigatório!'
                                                                }
                                                            ]}
                                                        >
                                                            <Input />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={24}>
                                                        <Form.Item
                                                            {...field}
                                                            label="Modo"
                                                            name={[
                                                                field.name,
                                                                'size'
                                                            ]}
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message:
                                                                        'Modo é obrigatório!'
                                                                }
                                                            ]}
                                                        >
                                                            <Select>
                                                                {modeOptions.map(
                                                                    (
                                                                        option
                                                                    ) => (
                                                                        <Select.Option
                                                                            key={
                                                                                option.value
                                                                            }
                                                                            value={
                                                                                option.value
                                                                            }
                                                                        >
                                                                            {
                                                                                option.name
                                                                            }
                                                                        </Select.Option>
                                                                    )
                                                                )}
                                                            </Select>
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col span={2}>
                                                <MinusCircleOutlined
                                                    style={{ marginTop: 20 }}
                                                    onClick={() =>
                                                        remove(field.name)
                                                    }
                                                />
                                            </Col>
                                        </Row>
                                    </div>
                                ))}
                                <Form.Item>
                                    <Button
                                        type="dashed"
                                        onClick={() => {
                                            add({})
                                        }}
                                        style={{ width: '100%' }}
                                    >
                                        <PlusOutlined /> Adicionar
                                    </Button>
                                </Form.Item>
                            </>
                        )}
                    </Form.List>
                </Card>

                <Card
                    style={displayIf(hostType, 'ONSTAGE')}
                    title="Materiais para download"
                >
                    <Form.List name="resources">
                        {(fields, { add, remove }) => (
                            <>
                                {fields.map((field, index) => (
                                    <div key={`resources.${index}`}>
                                        <Row
                                            className="actionsBox"
                                            gutter={24}
                                            align="middle"
                                        >
                                            <Col span={24}>
                                                <Paragraph>
                                                    Recurso {index + 1}
                                                </Paragraph>
                                            </Col>
                                            <Col span={8}>
                                                <Form.Item
                                                    label="Titulo"
                                                    name={[field.name, 'title']}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message:
                                                                'Titulo é obrigatório!'
                                                        }
                                                    ]}
                                                >
                                                    <Input />
                                                </Form.Item>
                                            </Col>
                                            <Col span={14}>
                                                <Form.Item
                                                    {...field}
                                                    label="Link"
                                                    name={[field.name, 'link']}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message:
                                                                'Link é obrigatório!'
                                                        }
                                                    ]}
                                                >
                                                    <Input />
                                                </Form.Item>
                                            </Col>
                                            <Col span={2}>
                                                <MinusCircleOutlined
                                                    style={{ marginTop: 20 }}
                                                    onClick={() =>
                                                        remove(field.name)
                                                    }
                                                />
                                            </Col>
                                        </Row>
                                    </div>
                                ))}
                                <Form.Item>
                                    <Button
                                        type="dashed"
                                        onClick={() => {
                                            add({})
                                        }}
                                        style={{ width: '100%' }}
                                    >
                                        <PlusOutlined /> Adicionar
                                    </Button>
                                </Form.Item>
                            </>
                        )}
                    </Form.List>
                </Card>

                <Card
                    style={displayIf(hostType, 'ONSTAGE')}
                    title="Vídeos institucionais"
                >
                    <Form.List name="institutionalVideos">
                        {(fields, { add, remove }) => (
                            <>
                                {fields.map((field, index) => (
                                    <div key={`institutionalVideos.${index}`}>
                                        <Row
                                            className="actionsBox"
                                            gutter={24}
                                            align="middle"
                                        >
                                            <Col span={22}>
                                                <Paragraph>
                                                    Video {index + 1}
                                                </Paragraph>
                                                <Row gutter={24}>
                                                    <Col span={24}>
                                                        <Form.Item
                                                            {...field}
                                                            label="Url"
                                                            name={[
                                                                field.name,
                                                                'url'
                                                            ]}
                                                        >
                                                            <Input />
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col span={2}>
                                                <MinusCircleOutlined
                                                    style={{ marginTop: 20 }}
                                                    onClick={() =>
                                                        remove(field.name)
                                                    }
                                                />
                                            </Col>
                                        </Row>
                                    </div>
                                ))}
                                <Form.Item>
                                    <Button
                                        type="dashed"
                                        onClick={() => {
                                            add({})
                                        }}
                                        style={{ width: '100%' }}
                                    >
                                        <PlusOutlined /> Adicionar
                                    </Button>
                                </Form.Item>
                            </>
                        )}
                    </Form.List>
                </Card>

                <Card
                    style={displayIf(hostType, 'ONSTAGE')}
                    title="Página do patrocinador"
                >
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item label="Tags" name={['tags']}>
                                <Select
                                    mode="tags"
                                    tagRender={({ label, ...props }) => (
                                        <Tag {...props} color="#7F44FF">
                                            {label}
                                        </Tag>
                                    )}
                                    style={{ width: '100%' }}
                                    placeholder="Selecione uma tag existe ou crie uma nova."
                                >
                                    {TagsData?.brandTags?.map((tag) => (
                                        <Select.Option key={tag} value={tag}>
                                            {tag}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            {!isSponsor && (
                                <Form.Item
                                    label="Ordem de listagem descrescente"
                                    name="priority"
                                    {...getError('priority')}
                                >
                                    <InputNumber style={{ width: '100%' }} />
                                </Form.Item>
                            )}
                        </Col>
                    </Row>
                </Card>

                {
                    // CAMPOS OCULTOS
                    /* <Form.Item name="featured" valuePropName="checked">
                    <Checkbox>Co-realizador</Checkbox>
                </Form.Item>

                <Form.Item
                    label="Falar com stand"
                    name={['talkStand', 'url']}
                    rules={
                        standType === 'link'
                            ? [{ type: 'url', message: 'Url inválida' }]
                            : [{ type: 'email', message: 'E-mail inválido' }]
                    }
                    {...getError('talkStand.url')}
                >
                    <Input addonBefore={typeStandSelector} />
                </Form.Item>
                <Form.Item label="Arquivo" name="archive">
                    <MediaUploader type="doc" />
                </Form.Item> */
                }
            </Form>
        )
    }

    return (
        <CrudModal
            width={750}
            title={isEdit ? 'Editar Patrocinador' : 'Novo Patrocinador'}
            {...modalProps}
            {...props}
        >
            {WrapperForm()}
        </CrudModal>
    )
}

export default BrandsModal

import React from 'react'
import { useQuery, useMutation } from '@apollo/client'

import {
    CrudColumns,
    CrudList,
    CreateModalRoutes
} from '@cms/core/components/Crud'

import SponsorModal from './SponsorModal'

import { GET_SPONSORS_LIST_ORDERABLE } from '@cms/events/graphql/queries/user'
import { CURRENT_USER } from '@cms/core/graphql/queries/auth'
import { DELETE_USER } from '@cms/events/graphql/mutations/user'

const path = '/principal/patrocinador/usuarios'

const ModalRoutes = CreateModalRoutes(SponsorModal, path)

const columns = [
    {
        title: 'Nome',
        dataIndex: '',
        render: (_value, obj) => {
            return (
                <CrudColumns
                    image={obj.node?.image?.url}
                    title={obj.node.name}
                    text={[obj.node.jobTitle]}
                />
            )
        }
    },
    {
        title: 'E-mail',
        dataIndex: [''],
        render: (_value, obj) => {
            return (
                <CrudColumns
                    onlyText
                    text={[<strong>E-mail</strong>, obj.node.email]}
                />
            )
        }
    }
]

const orderByFields = [
    { label: 'Nome', value: 'name' },
    { label: 'Data criação', value: 'createdAt' }
]

const TeamList = () => {
    const query = useQuery(GET_SPONSORS_LIST_ORDERABLE, {
        variables: { first: 25, orderBy: { name: 'ASC' } }
    })

    const [deleteData] = useMutation(DELETE_USER, {
        refetchQueries: [GET_SPONSORS_LIST_ORDERABLE]
    })

    const { data } = useQuery(CURRENT_USER)

    if (query.error) return <div>Error: {query.error}</div>

    return (
        <>
            <ModalRoutes />
            <CrudList
                header={{
                    title: 'Patrocinadores',
                    subTitle: 'Adicione e gerencie patrocinadores',
                    countNames: {
                        singular: 'Patrocinador',
                        plural: 'Patrocinadores'
                    },
                    buttons: [
                        {
                            children: 'Novo Patrocinador',
                            type: 'primary',
                            link: path + '/adicionar'
                        }
                    ]
                }}
                search={true}
                columns={columns}
                queryName={'users'}
                onDelete={deleteData}
                orderByFields={orderByFields}
                disableFor={data.me.id}
                {...query}
            />
        </>
    )
}

export default TeamList


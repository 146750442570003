import React from 'react'
import { useParams } from 'react-router-dom'
import { useCreation } from '@umijs/hooks'
import { useQuery, useMutation } from '@apollo/client'

import {
    CrudList,
    CreateModalRoutes,
    CrudColumns
} from '@cms/core/components/Crud'

import BannersModal from './BannersModal'

import { GET_BANNER_LIST_ORDENABLE } from '@cms/events/graphql/queries/banner'
import { DELETE_BANNER } from '@cms/events/graphql/mutations/banner'
import { removeFromList } from '@cms/core/graphql/utils'
import { Checkbox, Select, Tag } from 'antd'
import { useState } from 'react'

const pages = ['Home', 'Sponsor', 'Live']

const columns = [
    {
        title: 'Link',
        dataIndex: ['node', 'link'],
        render: (value, obj) => {
            const integrations = obj.node?.integrations.map((i) => (
                <Tag color={i.active ? '#7F44FF' : 'red'}>{i.name}</Tag>
            ))
            return (
                <>
                    <CrudColumns
                        title={value}
                        text={integrations.length ? ['integrações:'] : []}
                        onlyText
                    />
                    <div
                        style={{
                            marginTop: 5
                        }}
                    >
                        {integrations}
                    </div>
                </>
            )
        }
    }
]

const BannersList = () => {
    const { eventId } = useParams()

    const query = useQuery(GET_BANNER_LIST_ORDENABLE, {
        variables: {
            first: 25,
            eventId,
            orderBy: { createdAt: 'DESC' },
            active: { in: [false, true] }
        }
    })

    const [deleteData] = useMutation(DELETE_BANNER, {
        update: removeFromList({ list: 'banners', Type: 'Banner' })
    })

    if (query.error) return <div>Error</div>

    const path = `/evento/${eventId}/banners`

    const ModalRoutes = useCreation(() => {
        return CreateModalRoutes(BannersModal, path, { eventId })
    }, [eventId])

    const orderByFields = [
        { label: 'Link', value: 'link' },
        { label: 'Data criação', value: 'createdAt' }
    ]

    const customFilter = (refetch) => {
        const [checked, setChecked] = useState(false)
        const [page, setPage] = useState()

        const handleActive = (status) => {
            setChecked(status)

            refetch({
                active: status ? { eq: status } : { in: [false, true] }
            })
        }

        const handlePage = (page) => {
            setPage(page)

            refetch({
                pages: page ? { in: [page] } : { in: pages }
            })
        }

        return (
            <>
                <Checkbox
                    checked={checked}
                    onChange={(e) => handleActive(e.target.checked)}
                >
                    Ativo
                </Checkbox>

                <Select
                    value={page}
                    onChange={handlePage}
                    placeholder="Página"
                    style={{
                        width: '120px'
                    }}
                >
                    {pages.map((value) => (
                        <Select.Option key={value} value={value}>
                            {value}
                        </Select.Option>
                    ))}
                </Select>
            </>
        )
    }

    return (
        <>
            <ModalRoutes />
            <CrudList
                header={{
                    title: 'Banners',
                    subTitle: 'Adicione e gerencie banners',
                    buttons: [
                        {
                            children: 'Novo',
                            type: 'primary',
                            link: path + '/adicionar'
                        }
                    ]
                }}
                columns={columns}
                search={true}
                queryName={'banners'}
                onDelete={deleteData}
                orderByFields={orderByFields}
                customFilter={customFilter}
                {...query}
            />
        </>
    )
}

export default BannersList

import {
    COMPONENTS,
    TRoute
} from '@cms/core/components/ThemeBuilder/context/types'

import { socialMediaTypes } from '@cms/core/components/ThemeBuilder/pages/Footer/constants'
import Sections from '../pages/Home/components/Sections'

export const BASE_URL = `/evento/:eventId/streaming/config`

export const ROUTES: TRoute[] = [
    {
        path: `tema`,
        name: COMPONENTS.Theme,
        home: true,
        exact: true,
        title: 'Tema'
    },
    {
        path: `images`,
        name: COMPONENTS.Images,
        exact: true,
        title: 'Imagens'
    },
    {
        path: `menu`,
        name: COMPONENTS.Menu,
        exact: true,
        title: 'Menu'
    },
    {
        path: `home`,
        name: COMPONENTS.Home,
        exact: true,
        title: 'Home',
        sections: Sections
    },
    {
        path: `scripts`,
        name: COMPONENTS.Scripts,
        exact: true,
        title: 'Scripts'
    },

    {
        path: `footer`,
        name: COMPONENTS.Footer,
        exact: true,
        title: 'Footer',
        subTitle: 'Configurações do Footer',
        sections: [
            {
                fields: [
                    {
                        name: 'backgroundColor',
                        label: 'Cor de fundo',
                        component: 'select',
                        initialValue: 'default',
                        options: [
                            { label: 'Padrão', value: 'default' },
                            { label: 'Primária', value: 'primary' },
                            { label: 'Destaque', value: 'highlight' }
                        ],
                        configs: {
                            mode: 'single'
                        }
                    },
                    {
                        name: 'social',
                        label: {
                            plural: 'Redes Sociais',
                            singular: 'Rede'
                        },
                        component: 'card-multiselect',
                        fields: [
                            {
                                label: 'Plataforma',
                                name: 'type',
                                component: 'select',
                                options: socialMediaTypes.map((s) => ({
                                    label: s,
                                    value: s
                                })),
                                configs: {
                                    mode: 'single',
                                    required: true
                                }
                            },
                            {
                                label: 'Url',
                                name: 'url',
                                component: 'input',
                                configs: {
                                    required: true
                                }
                            }
                        ]
                    },
                    {
                        name: 'links',
                        label: {
                            plural: 'Links',
                            singular: 'Link'
                        },
                        component: 'card-multiselect',
                        fields: [
                            {
                                label: 'Título',
                                name: 'label',
                                component: 'input',
                                configs: {
                                    required: true
                                }
                            },
                            {
                                label: 'Link',
                                name: 'url',
                                component: 'input',
                                configs: {
                                    required: true
                                }
                            }
                        ]
                    }
                ]
            }
        ]
    },
    {
        path: `configs`,
        name: COMPONENTS.Configs,
        exact: true,
        title: 'Outras configurações'
    }
]

import { useMutation, useQuery } from '@apollo/client'
import {
    CreateModalRoutes,
    CrudColumns,
    CrudList
} from '@cms/core/components/Crud'
import { CreateDashboardRoute } from '@cms/events/components/DashboardTab/utils'
import { DELETE_TALK, UPDATE_TALK } from '@cms/events/graphql/mutations/talk'
import { GET_TALK_LIST } from '@cms/events/graphql/queries/talk'
import { removeFromList } from '@cms/core/graphql/utils'
import { useCreation } from '@umijs/hooks'
import { Dropdown, Menu, Row, Space, Tag } from 'antd'
import React from 'react'
import { Link, useParams } from 'react-router-dom'

import TalkControlPanel from '../Talks/TalkControlPanel'
import TalksModal from '../Talks/TalksModal'
import StageDashboard from './Dashboard'

const type = 'STAGE'

const StageList = () => {
    const { eventId } = useParams()

    const query = useQuery(GET_TALK_LIST, {
        variables: {
            eventId,
            type,
            orderBy: { createdAt: 'ASC' }
        }
    })

    const [deleteData] = useMutation(DELETE_TALK, {
        update: removeFromList({
            list: 'talks',
            Type: 'Talk'
        })
    })

    const [updateTalk] = useMutation(UPDATE_TALK)

    const path = `/evento/${eventId}/palcos`

    const DashboardRoute = useCreation(() => {
        return CreateDashboardRoute(StageDashboard, path, { eventId })
    }, [eventId])

    const ModalRoutes = useCreation(() => {
        const Routers = CreateModalRoutes(TalksModal, path, {
            eventId,
            type,
            label: 'Palco'
        })

        return () => (
            <>
                <Routers />
                <Routers.Custom
                    path={`${path}/:id/painel`}
                    render={() => (
                        <TalkControlPanel
                            type={type}
                            eventId={eventId}
                            path={path}
                        />
                    )}
                />
            </>
        )
    }, [eventId])

    const columns = [
        {
            title: '',
            dataIndex: [''],
            with: 200,
            render: (value, obj) => {
                return <CrudColumns title={obj.node.title} onlyText />
            }
        },
        {
            title: 'Contém vídeo?',
            dataIndex: ['node', 'video'],
            width: 300,
            align: 'center',
            render: (video, obj) => {
                const hasVideos = [
                    video,
                    ...(obj.node.videos || []).map((o) => o.video)
                ].some((o) => o && o.url)
                return (
                    <Row style={{ paddingTop: '8px' }}>
                        <span style={{ marginRight: '5px' }}>
                            Link do vídeo cadastrado:
                        </span>
                        {hasVideos ? (
                            <Tag color="#7F44FF">Sim</Tag>
                        ) : (
                            <Tag color="#646a6a">Não</Tag>
                        )}
                    </Row>
                )
            }
        }
    ]

    const actionsExtra = {
        dataIndex: '',
        key: 'x',
        width: 400,
        align: 'right',
        render: (text, record) => {
            const id = record.node.id
            const menu = (
                <Menu>
                    <Menu.Item key="1">
                        <Link to={path + '/' + id + '/painel'}>
                            Painel de Controle
                        </Link>
                    </Menu.Item>
                </Menu>
            )

            return (
                <Space>
                    <Dropdown.Button type="primary" overlay={menu}>
                        <Link to={path + '/' + id + '/dashboard'}>
                            Dashboard
                        </Link>
                    </Dropdown.Button>
                </Space>
            )
        }
    }

    const orderByFields = [
        { label: 'Nome', value: 'name' },
        { label: 'Data criação', value: 'createdAt' },
        { label: 'Prioridade', value: 'priority' }
    ]

    if (query.error) return null

    return (
        <>
            <DashboardRoute />
            <ModalRoutes />
            <CrudList
                header={{
                    title: 'Palcos',
                    countNames: {
                        singular: 'Palco',
                        plural: 'Palcos'
                    },
                    subTitle: 'Adicione e gerencie seus palcos',
                    buttons: [
                        {
                            children: 'Novo Palco',
                            type: 'primary',
                            link: path + '/adicionar'
                        }
                    ]
                }}
                columns={columns}
                queryName={'talks'}
                onDelete={deleteData}
                search={true}
                actionsExtra={actionsExtra}
                orderByFields={orderByFields}
                {...query}
            />
        </>
    )
}

export default StageList


import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { getCurrentMainModule, getCurrentModules, selectMenu } from '../common'

export default function useMenu(_menu) {
    const location = useLocation()

    const menu = useSelector((state) => selectMenu(state, _menu))

    const { selectedMenus, secondMenu } = useMemo(() => {
        const currentMenu = getCurrentMainModule(_menu, location.pathname)

        const selectedMenus = getCurrentModules(_menu, location.pathname).map(
            (o) => o.module
        )

        return {
            secondMenu: currentMenu?.children,
            selectedMenus: [...new Set(selectedMenus)]
        }
    }, [location.pathname, _menu])

    return {
        selectedMenus,
        mainMenu: menu,
        secondMenu
    }
}

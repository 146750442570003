import React, { useState, useEffect, useRef, useCallback } from 'react'

import { HexColorPicker } from 'react-colorful'

import { ColorBadge } from './styles'

import useClickOutside from './useClickOutside'

const PopoverColor = (props) => {
    const { color, onChange } = props
    const popover = useRef<HTMLDivElement>()
    const [isOpen, toggle] = useState(false)

    const close = useCallback(() => toggle(false), [])
    useClickOutside(popover, close)

    useEffect(() => {
        if (isOpen && popover.current) {
            const rect = popover.current.parentElement.getBoundingClientRect()
            popover.current.style.top = rect.top + 'px'
            popover.current.style.left = rect.right + 10 + 'px'
            popover.current.style.opacity = '1'
        }
    }, [isOpen, popover])

    return (
        <ColorBadge color={color} onClick={() => toggle(true)}>
            {isOpen && (
                <div className="popover" ref={popover}>
                    <HexColorPicker
                        onChange={onChange}
                        color={color ? color : '#aabbcc'}
                    />
                </div>
            )}
        </ColorBadge>
    )
}

export default PopoverColor

import { gql } from '@apollo/client'
import * as fragments from '../fragments'

export const GET_USERS_REPORTEDS = gql`
    query UsersAndReports($first: Int, $afterCursor: Cursor, $search: String) {
        usersAndReports(first: $first, after: $afterCursor, search: $search) {
            totalCount
            edges {
                node {
                    user {
                        id
                        name
                        email
                        banned
                    }
                    reportsTotalCount
                }
            }
        }
    }
`

export const GET_USERS_REPORTEDS_ORDERABLE = gql`
    query UsersAndReports(
        $first: Int
        $afterCursor: Cursor
        $search: String
        $orderBy: UserReportAggregationOrderBy
    ) {
        usersAndReports(
            first: $first
            after: $afterCursor
            search: $search
            orderBy: $orderBy
        ) {
            totalCount
            edges {
                node {
                    user {
                        id
                        name
                        email
                        banned
                    }
                    reportsTotalCount
                }
            }
        }
    }
`

export const GET_USER_REPORTED = gql`
    query GetUser($reported: ObjectID!, $orderBy: UserReportOrderBy) {
        userReports(filter: { reported: $reported }, orderBy: $orderBy) {
            edges {
                node {
                    ...UserReportData
                }
            }
        }
    }

    ${fragments.UserReportData}
`

import React from 'react'
import { useParams } from 'react-router-dom'
import { useCreation } from '@umijs/hooks'
import { useQuery, useMutation } from '@apollo/client'
import { Space, Tag } from 'antd'

import {
    CrudColumns,
    CrudList,
    CreateModalRoutes
} from '@cms/core/components/Crud'
import { ButtonLink } from '@cms/events/components/Buttons'

import NotificationModal from './NotificationModal'

import { GET_NOTIFICATION_LIST_ORDERABLE } from '@cms/events/graphql/queries/notification'
import { DELETE_NOTIFICATION } from '@cms/events/graphql/mutations/notification'
import { removeFromList } from '@cms/core/graphql/utils'
import dayjs from 'dayjs'

const columns = [
    {
        title: 'Título',
        dataIndex: ['node', 'title'],
        render: (value, obj) => {
            const integrations = obj.node?.integrations.map((i) => (
                <Tag color={i.active ? '#7F44FF' : 'red'}>{i.name}</Tag>
            ))
            return (
                <>
                    <CrudColumns
                        title={value}
                        text={integrations.length ? ['integrações:'] : []}
                        onlyText
                    />
                    <div
                        style={{
                            marginTop: 5
                        }}
                    >
                        {integrations}
                    </div>
                </>
            )
        }
    },
    {
        title: 'Envio',
        dataIndex: '',
        render: (_, obj) => {
            const date = dayjs(new Date(obj.node.createdAt)).format(
                'DD MMM YYYY [-] HH:mm'
            )
            return <CrudColumns onlyText title="Enviado" text={[date]} />
        }
    }
]

const NotificationList = () => {
    const { eventId } = useParams()

    const query = useQuery(GET_NOTIFICATION_LIST_ORDERABLE, {
        variables: { first: 25, eventId, orderBy: { createdAt: 'DESC' } }
    })

    const [deleteData] = useMutation(DELETE_NOTIFICATION, {
        update: removeFromList({ list: 'notifications', Type: 'Notification' })
    })

    if (query.error) return <div>Error</div>

    const path = `/evento/${eventId}/notificacoes`

    const ModalRoutes = useCreation(() => {
        return CreateModalRoutes(NotificationModal, path, { eventId })
    }, [eventId])

    const orderByFields = [{ label: 'Data de envio', value: 'createdAt' }]

    return (
        <>
            <ModalRoutes />
            <CrudList
                header={{
                    title: 'Notificações',
                    countNames: {
                        singular: 'Notificação',
                        plural: 'Notificações'
                    },
                    subTitle:
                        'Envie Notificações para usuários na pagina de transmissão',
                    buttons: [
                        {
                            children: 'Nova Notificação',
                            type: 'primary',
                            link: path + '/adicionar'
                        }
                    ]
                }}
                search={true}
                columns={columns}
                queryName={'notifications'}
                onDelete={deleteData}
                hideAction={'edit'}
                orderByFields={orderByFields}
                {...query}
            />
        </>
    )
}

export default NotificationList


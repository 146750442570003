import { NAVTITLES } from './labels'
export const BASE_URL = `/evento/:eventId/paginas/`

export const ROUTES = {
    config: [
        {
            path: ``,
            name: 'config',
            exact: true,
            title: NAVTITLES.config
        },
        {
            path: `config/seo`,
            name: 'seo',
            title: NAVTITLES.seo
        },
        {
            path: `config/form`,
            name: 'modalFormStyles',
            title: NAVTITLES.modalFormStyles
        },
        {
            path: `config/scripts`,
            name: 'scripts',
            title: NAVTITLES.scripts
        },
        {
            path: `config/cookies`,
            name: 'cookies',
            title: NAVTITLES.cookies
        }
    ],
    navigations: [
        {
            path: `navigations/menu`,
            name: 'menu',
            title: NAVTITLES.menu
        }
    ],
    pages: [
        {
            path: `page/home`,
            name: 'home',
            title: NAVTITLES.home
        },
        {
            path: `page/speaker`,
            name: 'speaker',
            title: NAVTITLES.speaker
        },
        {
            path: `page/sponsor`,
            name: 'sponsor',
            title: NAVTITLES.sponsor
        },
        {
            path: `page/indication`,
            name: 'indication',
            title: NAVTITLES.indication
        }
    ]
}

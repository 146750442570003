import React from 'react'

import {
    Checkbox,
    Form,
    InputNumber,
    Input,
    Card,
    Button,
    Row,
    Col,
    Typography
} from 'antd'

import SelectSearch from '@cms/core/components/SelectSearch'
import { TField } from './types'
import { InputTranslation } from '@cms/core/components/InputTranslation'
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'

const { Paragraph } = Typography

export const ConfigFields = ({ fields }: { fields: TField[] }) => {
    return (
        <>
            {fields.map((field) => {
                const {
                    name,
                    label,
                    component,
                    initialValue,
                    fields: _subFields,
                    options,
                    configs
                } = field

                if (component === 'select') {
                    return (
                        <Form.Item
                            name={name}
                            label={label}
                            rules={[{ required: true }]}
                            initialValue={initialValue}
                        >
                            <SelectSearch options={options} {...configs} />
                        </Form.Item>
                    )
                }

                if (component === 'checkbox') {
                    return (
                        <Form.Item
                            name={name}
                            label={label}
                            valuePropName="checked"
                            rules={[{ required: true }]}
                        >
                            <Checkbox />
                        </Form.Item>
                    )
                }

                if (component === 'inputNumber') {
                    return (
                        <Form.Item
                            name={name}
                            label={label}
                            rules={[{ required: true }]}
                        >
                            <InputNumber />
                        </Form.Item>
                    )
                }

                if (component === 'html') {
                    return (
                        <Form.Item
                            name={name}
                            label={label}
                            rules={[{ required: true }]}
                        >
                            <InputTranslation mode="html" />
                        </Form.Item>
                    )
                }

                if (component === 'input') {
                    return (
                        <Form.Item
                            name={name}
                            label={label}
                            rules={[{ required: true }]}
                        >
                            <Input {...configs} />
                        </Form.Item>
                    )
                }

                if (
                    component === 'card-multiselect' &&
                    typeof label !== 'string'
                ) {
                    return (
                        <Card title={label.plural}>
                            <Form.List name={name}>
                                {(fields, { add, remove }) => (
                                    <>
                                        {fields.map((field, index) => (
                                            <div key={`${name}.${index}`}>
                                                <Row
                                                    className="actionsBox"
                                                    gutter={24}
                                                    align="middle"
                                                >
                                                    <Col span={24}>
                                                        <Paragraph>
                                                            {label.singular}{' '}
                                                            {index + 1}
                                                        </Paragraph>
                                                    </Col>
                                                    {_subFields.map(
                                                        (subField) => {
                                                            const _subField = {
                                                                ...subField
                                                            }
                                                            _subField.name = [
                                                                field.name.toString(),
                                                                _subField.name as string
                                                            ]
                                                            return (
                                                                <>
                                                                    <Col
                                                                        span={
                                                                            10
                                                                        }
                                                                    >
                                                                        <ConfigFields
                                                                            fields={[
                                                                                _subField
                                                                            ]}
                                                                        />
                                                                    </Col>
                                                                </>
                                                            )
                                                        }
                                                    )}

                                                    <Col span={2}>
                                                        <MinusCircleOutlined
                                                            style={{
                                                                marginTop: 20
                                                            }}
                                                            onClick={() =>
                                                                remove(
                                                                    field.name
                                                                )
                                                            }
                                                        />
                                                    </Col>
                                                </Row>
                                            </div>
                                        ))}
                                        <Form.Item>
                                            <Button
                                                type="dashed"
                                                onClick={() => {
                                                    add({})
                                                }}
                                                style={{ width: '100%' }}
                                            >
                                                <PlusOutlined /> Adicionar
                                            </Button>
                                        </Form.Item>
                                    </>
                                )}
                            </Form.List>
                        </Card>
                    )
                }

                return null
            })}
        </>
    )
}


import React from 'react'
import { Form, Select } from 'antd'

import BaseConfig from '../BaseConfig'
import { COLLECTIONS_CONFIGS } from '../../Segment/constants/collections'
import Segment from '@cms/core/components/Segment'

const Collections = (props) => {
    return (
        <>
            <BaseConfig name={props.name}>
                <Form.Item
                    name={[props.name, 'config', 'variant']}
                    label="Tipo de visualização"
                >
                    <Select>
                        <Select.Option value="default">Padrão</Select.Option>
                        <Select.Option value="withCta">Com CTA</Select.Option>
                    </Select>
                </Form.Item>
            </BaseConfig>
            <Segment
                form={props.form}
                name={[props.name, 'config', 'condition']}
                type="groupCollections"
                filters={COLLECTIONS_CONFIGS}
            />
        </>
    )
}

export default Collections


import Modal from '@cms/core/components/ModalFull'
import React, { useEffect } from 'react'
import ModalContent from './modalContent'

const TalkControlPanel = ({ params, eventId, type, ...props }) => {
    useEffect((_) => {
        document.body.style.overflowY = 'hidden'
        return (_) => {
            document.body.style.overflowY = ''
        }
    }, [])

    return (
        <Modal
            disableFooter={true}
            visible={true}
            showMenu={true}
            children={
                <ModalContent params={params} type={type} eventId={eventId} />
            }
        />
    )
}

export default TalkControlPanel

import { gql } from '@apollo/client'
import * as fragments from '../fragments'

export const GET_CUSTOMER_LIST_ORDENABLE = gql`
    query GetCustomers(
        $first: Int
        $afterCursor: Cursor
        $orderBy: CustomerOrderBy
        $search: String
    ) {
        customers(
            first: $first
            after: $afterCursor
            orderBy: $orderBy
            search: $search
        ) {
            totalCount
            pageInfo {
                hasNextPage
                endCursor
            }
            edges {
                node {
                    ...CustomerData
                }
            }
        }
    }

    ${fragments.CustomerData}
`

export const GET_CUSTOMER = gql`
    query GetCustomer($id: ObjectID!) {
        customer(id: $id) {
            ...CustomerData
        }
    }

    ${fragments.CustomerData}
`

export const GET_CURRENT_CUSTOMER = gql`
    query GetCurrentCustomer {
        currentCustomer {
            ...CustomerData
        }
    }

    ${fragments.CustomerData}
`

import { gql } from '@apollo/client'
import * as fragments from '../fragments'

export const GET_BRAND_PLAN_LIST_ORDENABLE = gql`
    query GetBrandPlans(
        $first: Int
        $afterCursor: Cursor
        $orderBy: BrandPlanOrderBy
        $search: String
    ) {
        brandPlans(
            first: $first
            after: $afterCursor
            orderBy: $orderBy
            search: $search
        ) {
            totalCount
            pageInfo {
                hasNextPage
                endCursor
            }
            edges {
                node {
                    ...BrandPlanData
                }
            }
        }
    }

    ${fragments.BrandPlanData}
`

export const GET_BRAND_PLANS_LIST = gql`
    query GetBrandPlansList($first: Int, $afterCursor: Cursor) {
        brandPlans(first: $first, after: $afterCursor) {
            totalCount
            pageInfo {
                hasNextPage
                endCursor
            }
            edges {
                node {
                    ...BrandPlanData
                }
            }
        }
    }

    ${fragments.BrandPlanData}
`

export const GET_BRAND_PLAN = gql`
    query GetBrandPlan($id: ObjectID!) {
        brandPlan(id: $id) {
            ...BrandPlanData
        }
    }

    ${fragments.BrandPlanData}
`

import React from 'react'
import { Spin } from 'antd'

import { Wrapper, Content } from './styles'
import { TLoaderProps } from './types'

const Loader = ({ fixed = true, padding }: TLoaderProps) => {
    const Spinner = () => {
        return (
            <Content fixed={fixed} padding={padding}>
                <Spin />
            </Content>
        )
    }

    return fixed ? Spinner() : <Wrapper>{Spinner()}</Wrapper>
}

export default Loader

import React, { useState } from 'react'
import { useLocation, useParams, Link } from 'react-router-dom'
import { Col, Button, Row, message } from 'antd'

import { Wrapper, Navigation } from './styles'
import { DownloadOutlined } from '@ant-design/icons'
import ImportModal from './ImportModal'
import ConfirmationModal from './ConfirmationModal'
import Affix from '../Affix'
import { TNavRoute } from './types'

export interface INavProps {
    idParam: string
    save?: () => Promise<any>
    importModal?: boolean
    config: {
        routes: TNavRoute[]
        baseUrl: string
    }
}

export function Nav({ idParam, save, importModal = false, config }: INavProps) {
    const params = useParams<any>()
    const ID = params?.[idParam]
    const { pathname } = useLocation()
    const [loading, setLoading] = useState(false)
    const [selectedEvent, setSelectedEvent] = useState(false)
    const [showImportModal, setShowImportModal] = useState(false)
    const [showConfirmationImportModal, setShowConfirmationImportModal] =
        useState(false)

    const baseUrl = !ID
        ? config.baseUrl
        : config.baseUrl.replace(':' + idParam, ID)
    const currentPath = pathname.replace(baseUrl, '').split('/')

    const handleToggleImportModal = () => {
        setShowImportModal((show) => !show)
    }

    const handleImportTemplate = (event) => {
        setSelectedEvent(event)
        handleToggleImportModal()
        setShowConfirmationImportModal(true)
    }

    const handlePublished = async () => {
        setLoading(true)
        await save()
        message.success('Publicado com sucesso!')
        setLoading(false)
    }

    return (
        <Wrapper>
            <Affix offsetTop={0} zIndex={999} isNav={true}>
                <Row className="header" justify="space-between">
                    <Col>
                        <Navigation>
                            <Col>
                                {config.routes.map(({ path, title }) => (
                                    <Link
                                        key={path}
                                        to={`${baseUrl}/${path}`}
                                        className={
                                            currentPath.includes(path) ||
                                            currentPath.join('/') === ''
                                                ? 'active-nav'
                                                : ''
                                        }
                                    >
                                        {title}
                                    </Link>
                                ))}
                            </Col>
                        </Navigation>
                    </Col>
                    <Row className="actions">
                        {importModal && (
                            <Button
                                type="primary"
                                onClick={handleToggleImportModal}
                                icon={<DownloadOutlined />}
                            >
                                Importar transmissão
                            </Button>
                        )}
                        {save && (
                            <Button
                                loading={loading}
                                type="primary"
                                onClick={handlePublished}
                            >
                                Salvar e Publicar
                            </Button>
                        )}
                    </Row>
                </Row>

                {showImportModal && (
                    <ImportModal
                        onCancel={handleToggleImportModal}
                        onImport={handleImportTemplate}
                    />
                )}

                {showConfirmationImportModal && (
                    <ConfirmationModal
                        event={selectedEvent}
                        onCancel={() => setShowConfirmationImportModal(false)}
                    />
                )}
            </Affix>
        </Wrapper>
    )
}


import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { Modal, Button } from 'antd'
import Loader from '../../Loader'

const CrudModal = ({
    visible = true,
    path,
    saveLoading,
    dataLoading,
    onClose,
    onSave,
    children,
    saveLabel = 'Enviar',
    disableFooter,
    redirect = true,
    mode = 'write',
    ...props
}) => {
    const [_visible, setVisible] = useState(visible)
    const history = useHistory()

    useEffect(() => {
        setVisible(visible)
    }, [visible])

    const handleCancel = () => {
        setVisible(false)
        if (path) {
            setTimeout(() => {
                history.push(path)
            }, 200)
        }
    }

    const handleSave = async () => {
        const result = await onSave()

        if (result && redirect) {
            handleCancel()
        }
    }

    return (
        <Modal
            open={_visible}
            className={mode === 'view' && 'crud-modal-readOnly'}
            onCancel={handleCancel}
            footer={
                !disableFooter && mode === 'write'
                    ? [
                          <Button key="back" onClick={handleCancel}>
                              Cancelar
                          </Button>,
                          <Button
                              data-cy="save-button"
                              key="submit"
                              type="primary"
                              loading={saveLoading}
                              onClick={handleSave}
                          >
                              {saveLabel}
                          </Button>
                      ]
                    : null
            }
            {...props}
        >
            {dataLoading && <Loader />}
            {children}
        </Modal>
    )
}

export default CrudModal

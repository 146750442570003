import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { useMutation } from '@apollo/client'

import { Button } from 'antd'

import { UPDATE_TALK } from '@cms/events/graphql/mutations/talk'

import FinishedButton from './Finished'

const Buttons = ({ eventId, type, status, refetch }) => {
    const { id } = useParams()
    const [updateTalk] = useMutation(UPDATE_TALK)
    const [loadingStatus, setLoadingStatus] = useState(false)

    const handleChangeStatus = async (status, others) => {
        setLoadingStatus(true)
        await updateTalk({
            variables: {
                id,
                event: eventId,
                status,
                ...others
            }
        })
        await refetch()
        setLoadingStatus(false)
    }

    if (type === 'LIVE' || type === 'STAGE') {
        return (
            <>
                {status === 'WAITING' && (
                    <Button
                        onClick={() => handleChangeStatus('STARTED')}
                        style={{
                            color: '#fff',
                            marginBottom: 20,
                            backgroundColor: 'rgb(0, 211, 148)'
                        }}
                        loading={loadingStatus}
                    >
                        Iniciar Live
                    </Button>
                )}

                {status === 'FINISHED' && (
                    <Button
                        onClick={() => handleChangeStatus('WAITING')}
                        style={{
                            color: '#fff',
                            marginBottom: 20,
                            backgroundColor: 'rgb(36 190 194)'
                        }}
                        loading={loadingStatus}
                    >
                        Voltar para aguardando
                    </Button>
                )}

                {status === 'STARTED' && (
                    <FinishedButton
                        loadingStatus={loadingStatus}
                        eventId={eventId}
                        onClick={handleChangeStatus}
                    />
                )}
            </>
        )
    }

    return (
        <>
            {status === 'WAITING' && (
                <Button
                    onClick={() => handleChangeStatus('FINISHED')}
                    style={{
                        color: '#fff',
                        marginBottom: 20,
                        backgroundColor: 'rgb(0, 211, 148)'
                    }}
                    loading={loadingStatus}
                >
                    Liberar Vídeo
                </Button>
            )}

            {(status === 'FINISHED' || status === 'STARTED') && (
                <Button
                    onClick={() => handleChangeStatus('WAITING')}
                    style={{
                        color: '#fff',
                        marginBottom: 20,
                        backgroundColor: 'rgb(36 190 194)'
                    }}
                    loading={loadingStatus}
                >
                    Voltar para aguardando
                </Button>
            )}
        </>
    )
}

export default Buttons

import styled from 'styled-components'

const deskWidth = 1240
// const deskWidth = 380
const mobileWidth = 767
// const previewWidth = 581

export const PreviewBoxWrapper = styled.div`
    .btn-code {
        border-color: ${({ theme }) => theme.color.primaryColor} !important;
        color: ${({ theme }) => theme.color.primaryColor} !important;
        margin-left: 20px;
    }

    .ant-radio-button-wrapper-checked {.ant-radio-button-wrapper
        border-color: ${({ theme }) => theme.color.activeColors} !important;
        background-color: ${({ theme }) => theme.color.activeColors} !important;
        span {
            color: #fff !important;
        }
    }

    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
        background-color: ${({ theme }) => theme.color.activeColors} !important;
    }

    .preview-widget-box {
        max-height: calc(100vh - 260px);
        margin-top: 20px;
        padding: 40px 0;
        background-color: ${({ background }) =>
            background === 'light' ? '#fff' : 'transparent'};

        iframe {
            width: ${deskWidth}px !important;
            max-height: 100vh;
            border: none;
            transform: ${({ width }) =>
                width ? `scale(${width / deskWidth})` : 'scale(1)'};
            transform-origin: 0 0;

            @media (max-width: ${mobileWidth}px) {
                transform: ${({ width }) =>
                    width ? `scale(${width / mobileWidth})` : 'scale(1)'};
                transform-origin: 0 0;
            }
        }
    }
`

import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { useCreation } from '@umijs/hooks'
import { useQuery, useMutation } from '@apollo/client'

import { Checkbox, Row, Tag } from 'antd'

import {
    CrudColumns,
    CrudLinks,
    CrudList,
    CreateModalRoutes
} from '@cms/core/components/Crud'

import SpeakersModal from './SpeakersModal'

import { GET_SPEAKER_LIST_ORDERABLE } from '@cms/events/graphql/queries/speaker'
import { DELETE_SPEAKER } from '@cms/events/graphql/mutations/speaker'
import { removeFromList } from '@cms/core/graphql/utils'

const columns = [
    {
        title: 'Nome',
        dataIndex: '',
        // sorter: 'firstName',
        render: (value, obj) => {
            return (
                <CrudColumns
                    image={obj.node?.image?.url}
                    title={obj.node.firstName + ' ' + (obj.node.lastName || '')}
                    text={[obj.node.office]}
                />
            )
        }
    },
    {
        title: 'Empresa',
        dataIndex: '',
        render: (value, obj) => {
            return (
                <>
                    {obj.node.company.name ? (
                        <CrudColumns
                            image={obj.node.company?.image?.url}
                            title={obj.node.company.name}
                        />
                    ) : (
                        '---'
                    )}
                </>
            )
        }
    },
    // {
    //     title: 'Data',
    //     sorter: 'createdAt',
    //     dataIndex: '',
    //     render: (value, obj) => {
    //         return (
    //             <>{obj.node.createdAt ? <p>{obj.node.createdAt} </p> : '---'}</>
    //         )
    //     }
    // },
    {
        title: 'tags',
        dataIndex: ['node', 'tags'],
        render: (tags) => {
            return (
                <Row>
                    {tags?.map((tag) => (
                        <Tag key={tag} color="#7F44FF">
                            {tag}
                        </Tag>
                    ))}
                </Row>
            )
        }
    },
    // {
    //     title: 'priority',
    //     dataIndex: ['node', 'priority'],
    //     sorter: 'priority',
    //     render: (priority) => {
    //         return (
    //             <Row>
    //                 <p>{priority} </p>
    //             </Row>
    //         )
    //     }
    // },
    {
        title: 'Links',
        dataIndex: '',
        render: (value, obj) => {
            return (
                <>
                    {obj.node.links ? (
                        <CrudLinks links={obj.node.links} />
                    ) : (
                        '---'
                    )}
                </>
            )
        }
    }
]

const SpeakersList = (props) => {
    const { eventId } = useParams()

    const query = useQuery(GET_SPEAKER_LIST_ORDERABLE, {
        variables: { first: 25, eventId, orderBy: { firstName: 'ASC' } }
    })

    const [deleteData] = useMutation(DELETE_SPEAKER, {
        update: removeFromList({ list: 'speakers', Type: 'Speaker' })
    })

    if (query.error) return <div>Error</div>

    const path = `/evento/${eventId}/palestrantes`

    const ModalRoutes = useCreation(() => {
        return CreateModalRoutes(SpeakersModal, path, { eventId })
    }, [eventId])

    const orderByFields = [
        { label: 'Nome', value: 'firstName' },
        { label: 'Data criação', value: 'createdAt' },
        { label: 'Prioridade', value: 'priority' }
    ]

    const customFilter = (refetch) => {
        const [checked, setChecked] = useState(false)

        const handleDestaque = (status) => {
            setChecked(status)

            refetch({
                featured: status ? status : undefined
            })
        }

        return (
            <>
                <Checkbox
                    checked={checked}
                    onChange={(e) => handleDestaque(e.target.checked)}
                >
                    Destaque
                </Checkbox>
            </>
        )
    }

    return (
        <>
            <ModalRoutes />
            <CrudList
                header={{
                    title: 'Palestrantes',
                    subTitle: 'Adicione e gerencie os palestrantes',
                    countNames: {
                        singular: 'Palestrante',
                        plural: 'Palestrantes'
                    },
                    buttons: [
                        {
                            children: 'Novo Palestrante',
                            type: 'primary',
                            link: path + '/adicionar'
                        }
                    ]
                }}
                search={true}
                columns={columns}
                queryName={'speakers'}
                onDelete={deleteData}
                orderByFields={orderByFields}
                customFilter={customFilter}
                // showHeader={true}
                {...query}
            />
        </>
    )
}

export default SpeakersList


import { useQuery } from '@apollo/client'
import { GET_COUNT_TOTAL_TALKS } from '@cms/events/graphql/queries/blocks/totals'
import React from 'react'

const DisplayTime = ({ eventId, talk }) => {
    const { loading, error, data } = useQuery(GET_COUNT_TOTAL_TALKS, {
        variables: { event: eventId, talk }
    })

    if (loading || error) return null

    return (
        <p>
            {data.talksViewTimeAverage.humanizedAverage
                ? data.talksViewTimeAverage.humanizedAverage.split(':')[0] +
                  ' min'
                : '0'}
            <span>Tempo médio</span>
        </p>
    )
}

export default DisplayTime

import React from 'react'
import { Form, Switch, Select } from 'antd'
import omit from 'lodash/omit'

import Colors from '../Colors'

import { Title } from '../../styles'

const BoxControl = ({ form }) => {
    const handleShouldUpdate = (prevValues, currentValues) => {
        const box = prevValues?.configs?.box !== currentValues?.configs?.box

        return box
    }

    return (
        <>
            <Title level={4}>Caixa</Title>
            <Form.Item
                name={['configs', 'box']}
                label="Box"
                valuePropName="checked"
            >
                <Switch />
            </Form.Item>
            <Form.Item noStyle shouldUpdate={handleShouldUpdate}>
                {({ getFieldValue }) => {
                    const box = getFieldValue(['configs', 'box'])

                    return box ? (
                        <>
                            <Form.Item
                                name={['configs', 'color']}
                                label="Cor de fundo"
                            >
                                <Colors />
                            </Form.Item>
                            <Form.Item
                                label="Formato caixa"
                                name={['configs', 'shape']}
                            >
                                <Select>
                                    <Option value="circle">Redondo</Option>
                                    <Option value="rounded">Arredondado</Option>
                                    <Option value="default">Quadrado</Option>
                                </Select>
                            </Form.Item>
                        </>
                    ) : null
                }}
            </Form.Item>
        </>
    )
}

export default BoxControl

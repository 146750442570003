import { gql } from '@apollo/client'
import * as fragments from '../fragments'

export const GET_FAQ_QUESTIONS_LIST = gql`
    query GetFaqQuestions(
        $first: Int
        $afterCursor: Cursor
        $eventId: ObjectID
        $topicId: ObjectID
    ) {
        faqQuestions(
            first: $first
            after: $afterCursor
            filter: { event: $eventId, topic: $topicId }
        ) {
            totalCount
            pageInfo {
                hasNextPage
                endCursor
            }
            edges {
                node {
                    ...FaqQuestionData
                }
            }
        }
    }

    ${fragments.FaqQuestionData}
`

export const GET_FAQ_QUESTIONS_LIST_ORDERABLE = gql`
    query GetFaqQuestions(
        $first: Int
        $afterCursor: Cursor
        $eventId: ObjectID!
        $topicId: ObjectID
        $orderBy: FaqQuestionOrderBy
        $search: String
    ) {
        faqQuestions(
            first: $first
            after: $afterCursor
            filter: { event: $eventId, topic: $topicId }
            search: $search
            orderBy: $orderBy
        ) {
            totalCount
            pageInfo {
                hasNextPage
                endCursor
            }
            edges {
                node {
                    ...FaqQuestionData
                }
            }
        }
    }

    ${fragments.FaqQuestionData}
`

export const GET_FAQ_QUESTION = gql`
    query GetQuestion($id: ObjectID!) {
        faqQuestion(id: $id) {
            ...FaqQuestionData
        }
    }

    ${fragments.FaqQuestionData}
`

import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Form, Divider, Input, Select } from 'antd'
import { useQuery } from '@apollo/client'
import { useSelector } from 'react-redux'

import { GET_EVENT_FORMS } from '@cms/events/graphql/queries/form'

import { Colors, BoxControl, FontSelector } from '../../components'
import { Wrapper, Title } from '../../styles'

import { useWidgetContext } from '../../context/widgets'

const { Option } = Select

const Fields = ({ form }) => {
    return (
        <>
            <FontSelector />
            <Form.Item
                name={['configs', 'event']}
                label="Texto"
                style={{ display: 'none' }}
            >
                <Input />
            </Form.Item>
            <Wrapper transparent>
                <Title level={5}>Formulário</Title>
                <Form.Item
                    name={['configs', 'form']}
                    label="Texto"
                    style={{ display: 'none' }}
                >
                    <Input />
                </Form.Item>
                <Form.Item name={['configs', 'textcolor']} label="Cor do texto">
                    <Colors />
                </Form.Item>
                <Form.Item name={['configs', 'shape']} label="Formato">
                    <Select>
                        <Option value="circle">Redondo</Option>
                        <Option value="rounded">Arredondado</Option>
                        <Option value="default">Quadrado</Option>
                    </Select>
                </Form.Item>
                <Form.Item
                    name={['configs', 'redirect']}
                    label="Redirecionamento"
                >
                    <Input />
                </Form.Item>
            </Wrapper>
            <Wrapper transparent>
                <Title level={5}>Botão</Title>
                <Form.Item name={['configs', 'btn']} label="Texto">
                    <Input />
                </Form.Item>
                <Form.Item name={['configs', 'btn-shape']} label="Formato">
                    <Select>
                        <Option value="circle">Redondo</Option>
                        <Option value="rounded">Arredondado</Option>
                        <Option value="default">Quadrado</Option>
                    </Select>
                </Form.Item>
                <Form.Item name={['configs', 'highlight']} label="Cor do fundo">
                    <Colors />
                </Form.Item>
                <Form.Item
                    name={['configs', 'highlight-text']}
                    label="Cor do texto"
                >
                    <Colors />
                </Form.Item>
            </Wrapper>
        </>
    )
}
const Preview = ({ db }) => {
    const { eventId } = useParams()

    if (!db) return null

    return (
        <div
            data-x-widget="viral"
            data-x-customer={db}
            data-x-preview="true"
            data-x-event={eventId}
            data-x-font="Montserrat"
            data-x-btn-shape="circle"
            data-x-highlight="#22D66A"
            data-x-highlight-text="#000"
            data-x-textcolor="#000"
            data-x-shape="rounded"
            data-x-redirect="https://example.com/"
        />
    )
}

export const Viral = {
    Fields,
    Preview
}

import Categories from './Categories'
import Series from './Series'
import Talks from './Talks'

export const TalksSections = {
    talks: {
        Component: Talks,
        name: 'talks',
        category: 'Palestras',
        label: 'Palestras',
        description: 'Seção sem filtro predefinidos',
        defaultConfig: {
            title: {
                'pt-BR': 'Palestras',
                'en-US': 'Talks',
                'es-ES': 'Conferencias',
                'pt-PT': 'Palestras'
            }
        }
    },
    previousTalks: {
        Component: Talks,
        name: 'talks',
        category: 'Palestras',
        label: 'Palestras passadas',
        description: 'Seção com filtro predefinido para palestras passadas',
        defaultConfig: {
            title: {
                'pt-BR': 'Palestras passadas',
                'en-US': 'Previous talks',
                'es-ES': 'Conferencias anteriores',
                'pt-PT': 'Palestras passadas'
            },
            condition: {
                type: 'AND',
                filters: [
                    {
                        field: 'type',
                        type: '$eq',
                        value: 'LIVE'
                    },
                    {
                        field: 'status',
                        type: '$in',
                        value: ['FINISHED']
                    }
                ]
            }
        }
    },
    nextTalks: {
        Component: Talks,
        name: 'talks',
        category: 'Palestras',
        label: 'Próximas palestras',
        description: 'Seção com filtro predefinido para palestras futuras',
        defaultConfig: {
            title: {
                'pt-BR': 'Próximas palestras',
                'en-US': 'Next talks',
                'es-ES': 'Próximas Conferencias',
                'pt-PT': 'Próximas palestras'
            },
            condition: {
                type: 'AND',
                filters: [
                    {
                        field: 'type',
                        type: '$eq',
                        value: 'LIVE'
                    },
                    {
                        field: 'status',
                        type: '$in',
                        value: ['WAITING']
                    }
                ]
            }
        }
    },
    categories: {
        Component: Categories,
        name: 'categories',
        category: 'Palestras',
        label: 'Trilhas',
        description: 'Todas as trilhas agrupadas e com seletor',
        defaultConfig: {
            title: {
                'pt-BR': 'Trilhas',
                'en-US': 'Tracks',
                'es-ES': 'Pistas',
                'pt-PT': 'Trilhas'
            },
            condition: {
                type: 'AND',
                filters: [
                    {
                        field: 'type',
                        type: '$eq',
                        value: 'MAIN'
                    }
                ]
            }
        }
    },
    series: {
        Component: Series,
        name: 'series',
        category: 'Palestras',
        label: 'Séries',
        description: 'Todas as séries separadas por linha',
        defaultConfig: {
            title: {
                'pt-BR': 'Séries',
                'en-US': 'Series',
                'es-ES': 'Series',
                'pt-PT': 'Séries'
            },
            condition: {
                type: 'AND',
                filters: [
                    {
                        field: 'type',
                        type: '$eq',
                        value: 'SERIES'
                    }
                ]
            }
        }
    }
}


import styled from 'styled-components'

export const ChatItemWrapper = styled.div`
    display: flex;
    align-items: center;
    max-width: 100%;
    .ant-avatar {
        min-width: 35px;
        max-width: 35px;
    }
    .ant-typography {
        margin: 0 10px;
        overflow-wrap: anywhere;
        span {
            font-weight: 600;
        }
    }
`

import { gql } from '@apollo/client'
import * as fragments from '../fragments'

export const SUBSCRIPTION_CHANNEL_MESSAGE = gql`
    subscription ChatMessage($channel: ChannelInput!) {
        chatMessage(channel: $channel) {
            id
            uid
            user {
                id
                name
                avatar {
                    ...MediaData
                }
            }
            message
        }
    }
    ${fragments.MediaData}
`

import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Form, Input } from 'antd'

import { Colors, BoxControl, FontSelector } from '../../components'
import { Wrapper, Title } from '../../styles'
import { useWidgetContext } from '../../context/widgets'

const Fields = ({ form }) => {
    return (
        <>
            <FontSelector />
            <Form.Item
                name={['configs', 'event']}
                label="Texto"
                style={{ display: 'none' }}
            >
                <Input />
            </Form.Item>
            <Wrapper transparent>
                <BoxControl form={form} />
            </Wrapper>
            <Wrapper transparent>
                <Title level={5}>Top</Title>
                <Form.Item name={['configs', 'topboxcolor']} label="Cor do box">
                    <Colors />
                </Form.Item>
                <Form.Item
                    name={['configs', 'toptextcolor']}
                    label="Cor do texto"
                >
                    <Colors />
                </Form.Item>
            </Wrapper>
            <Wrapper transparent>
                <Title level={5}>Cores</Title>
                <Form.Item
                    name={['configs', 'rewardboxcolor']}
                    label="Cor do box"
                >
                    <Colors />
                </Form.Item>
                <Form.Item name={['configs', 'indications']} label="Indicações">
                    <Colors />
                </Form.Item>
                <Form.Item name={['configs', 'rewards']} label="Recompensas">
                    <Colors />
                </Form.Item>
            </Wrapper>
        </>
    )
}
const Preview = ({ db }) => {
    const { eventId } = useParams()

    if (!db) return null

    return (
        <div
            data-x-widget="rewards"
            data-x-customer={db}
            data-x-preview="true"
            data-x-event={eventId}
            // data-x-event="5f5f6ac9a7dd244f0b6d14bc"
            data-x-font="Montserrat"
            data-x-box="false" // se box for "true" não precisa passar, se for "false" precisa passar o valor
            data-x-shape="rounded" // se box for "false" não precisa passar
            data-x-color="#fff" // se box for "false" não precisa passar
            data-x-topboxcolor="#D222D6"
            data-x-toptextcolor="#fff"
            data-x-indications="#D222D6"
            data-x-rewards="#000"
        />
    )
}

export const Rewards = {
    Fields,
    Preview
}

import React from 'react'
import { Switch, Redirect, Route, useParams } from 'react-router-dom'

import PrivateRoute from '@cms/core/routes/privateRoute'

/**
 * DynamicRoutes
 *
 * @param {*} baseUrl
 * @param {*} routes
 * @param {*} redirectToRoot
 * @returns
 */

export default function DynamicRoutes({
    baseUrl,
    routes,
    redirectToRoot,
    components
}) {
    const { eventId } = useParams()

    const mountUrl = (urls) => {
        return urls.join('/')
    }

    const mountPath = (path, isHome) => {
        if (redirectToRoot || !isHome) {
            return mountUrl([baseUrl, path])
        }

        return [baseUrl, mountUrl([baseUrl, path])]
    }

    const homeRoute = routes.find((route) => route.home)

    return (
        <Switch>
            {redirectToRoot && (
                <Route
                    exact
                    path={baseUrl + '/'}
                    render={() => (
                        <Redirect
                            to={{
                                pathname: mountUrl([
                                    baseUrl.replace(':eventId', eventId),
                                    homeRoute?.path
                                ])
                            }}
                        />
                    )}
                />
            )}
            {routes.map((route) => {
                const { path, exact, name, checkModulePath, checkModule } =
                    route

                return (
                    <PrivateRoute
                        key={path}
                        exact={exact}
                        checkModulePath={checkModulePath}
                        checkModule={checkModule}
                        path={mountPath(path, route?.home)}
                        component={components[name]}
                    />
                )
            })}
        </Switch>
    )
}

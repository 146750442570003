import { useMutation, useQuery } from '@apollo/client'
import { ButtonLink } from '@cms/events/components/Buttons'
import { CreateModalRoutes, CrudList } from '@cms/core/components/Crud'
import { DELETE_EVENT_FORM } from '@cms/events/graphql/mutations/form'
import { GET_EVENT_FORMS } from '@cms/events/graphql/queries/form'
import { useCreation } from '@umijs/hooks'
import { Space } from 'antd'
import React from 'react'
import { useParams } from 'react-router-dom'
import DetailsModal from '../components/DetailsModal'
import FormCreationModal from '../components/FormCreationModal'

const columns = [
    {
        title: 'Nome',
        dataIndex: ['node', 'name']
    }
]

const orderByFields = [{ label: 'Data criação', value: 'createdAt' }]

const OtherForm = () => {
    const { eventId } = useParams()
    const path = `/evento/${eventId}/forms/others`

    const variables = { eventId, type: 'others' }

    const query = useQuery(GET_EVENT_FORMS, { variables })
    const [deleteData] = useMutation(DELETE_EVENT_FORM, {
        refetchQueries: [{ query: GET_EVENT_FORMS, variables }]
    })

    const ModalRoutes = useCreation(() => {
        const Routers = CreateModalRoutes(FormCreationModal, path, {
            eventId,
            formType: 'others'
        })
        return () => (
            <>
                <Routers />
                <Routers.Custom
                    path={`${path}/:id/detalhes`}
                    render={() => (
                        <DetailsModal path={path} eventId={eventId} />
                    )}
                />
            </>
        )
    }, [eventId])

    if (query.error) {
        return <div>Error: {query.error}</div>
    }

    const actionsExtra = {
        dataIndex: '',
        key: 'x',
        width: 300,
        fixed: 'right',
        align: 'right',
        render: (text, record) => {
            const id = record.node.id

            return (
                <Space style={{ paddingRight: '10px' }}>
                    <ButtonLink
                        id={id}
                        path={`detalhes`}
                        key={id}
                        text={'Detalhes'}
                    />
                </Space>
            )
        }
    }

    return (
        <>
            <ModalRoutes />
            <CrudList
                header={{
                    title: 'Outros Formulários',
                    subTitle: 'Crie outros formulários',
                    buttons: [
                        {
                            children: 'Novo formulário',
                            type: 'primary',
                            link: path + '/adicionar'
                        }
                    ]
                }}
                search={true}
                columns={columns}
                queryName={'eventForms'}
                onDelete={deleteData}
                orderByFields={orderByFields}
                actionsExtra={actionsExtra}
                {...query}
            />
        </>
    )
}

export default OtherForm

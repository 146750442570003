import { gql } from '@apollo/client'
import { WatchPlanData } from '../fragments'

export const GET_WATCH_PLANS_LIST_ORDENABLE = gql`
    query GetWatchPlans(
        $first: Int
        $afterCursor: Cursor
        $orderBy: WatchPlanOrderBy
        $search: String
    ) {
        watchPlans(
            first: $first
            after: $afterCursor
            orderBy: $orderBy
            search: $search
        ) {
            totalCount
            pageInfo {
                hasNextPage
                endCursor
            }
            edges {
                node {
                    ...WatchPlanData
                }
            }
        }
    }

    ${WatchPlanData}
`

export const GET_WATCH_PLAN = gql`
    query GetWatchPlan($id: ObjectID!) {
        watchPlan(id: $id) {
            ...WatchPlanData
        }
    }

    ${WatchPlanData}
`

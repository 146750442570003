import React from 'react'
import { useQuery, useMutation } from '@apollo/client'

import {
    CrudColumns,
    CrudList,
    CreateModalRoutes
} from '@cms/core/components/Crud'

import ChiefUsersModal from './ChiefUserModal'

import { GET_USERS_BY_ROLE } from '@cms/events/graphql/queries/user'
import { CURRENT_USER } from '@cms/core/graphql/queries/auth'
import { DELETE_USER } from '@cms/events/graphql/mutations/user'

const path = '/principal/executivos/usuarios'

const ModalRoutes = CreateModalRoutes(ChiefUsersModal, path)

const columns = [
    {
        title: 'Nome',
        dataIndex: '',
        render: (_value, obj) => {
            return (
                <CrudColumns
                    image={obj.node?.image?.url}
                    title={obj.node.name}
                    text={[obj.node.jobTitle]}
                />
            )
        }
    },
    {
        title: 'E-mail',
        dataIndex: [''],
        render: (_value, obj) => {
            return (
                <CrudColumns onlyText title="E-mail" text={[obj.node.email]} />
            )
        }
    }
]

const orderByFields = [
    { label: 'Nome', value: 'name' },
    { label: 'Data criação', value: 'createdAt' }
]

const variables = { first: 25, orderBy: { name: 'ASC' }, role: 'chief' }

const TeamList = () => {
    const query = useQuery(GET_USERS_BY_ROLE, {
        variables
    })

    const [deleteData] = useMutation(DELETE_USER, {
        refetchQueries: [{ query: GET_USERS_BY_ROLE, variables }]
    })

    const { data } = useQuery(CURRENT_USER)

    if (query.error) return <div>Error: {query.error}</div>

    return (
        <>
            <ModalRoutes />
            <CrudList
                header={{
                    title: 'Executivos',
                    subTitle: 'Adicione e gerencie usuários executivos',
                    buttons: [
                        {
                            children: 'Novo Executivo',
                            type: 'primary',
                            link: path + '/adicionar'
                        }
                    ]
                }}
                search={true}
                columns={columns}
                queryName={'users'}
                onDelete={deleteData}
                orderByFields={orderByFields}
                disableFor={data.me.id}
                {...query}
            />
        </>
    )
}

export default TeamList

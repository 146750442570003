import React from 'react'
import { useHistory } from 'react-router'
import { LeftOutlined } from '@ant-design/icons'

import { IconWrapper } from './styles'

const ButtonReturn = ({ path }) => {
    const history = useHistory()

    const handleReturn = () => {
        if (path) {
            history.push(path)
        } else {
            history.goBack()
        }
    }

    return (
        <IconWrapper>
            <LeftOutlined
                color="#fff"
                style={{ padding: '10px' }}
                onClick={handleReturn}
            />
        </IconWrapper>
    )
}

export default ButtonReturn


import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'
import { CrudModal, useCrudForm } from '@cms/core/components/Crud'
import client from '@cms/core/graphql/client'
import { CREATE_QUIZ, UPDATE_QUIZ } from '@cms/events/graphql/mutations/quiz'
import { GET_QUIZ } from '@cms/events/graphql/queries/quiz'
import { addToList } from '@cms/core/graphql/utils'
import {
    Button,
    Card,
    Col,
    Form,
    Input,
    InputNumber,
    message,
    Row,
    Typography
} from 'antd'
import React, { useEffect } from 'react'

const { Paragraph } = Typography

const formatData = ({ ...data }) => {
    data.answers = data.answers.map((answer) => {
        return answer.answer
    })
    return data
}

const SurveyModal = ({ params, path, ...props }) => {
    const { id } = params
    useEffect((_) => {
        document.body.style.overflowY = 'hidden'
        return (_) => {
            document.body.style.overflowY = ''
        }
    }, [])

    const [{ form, isEdit, getError }, modalProps] = useCrudForm({
        getData: async (id) => {
            if (!id) return

            const response = await client.query({
                query: GET_QUIZ,
                variables: { id }
            })

            let data = {
                ...response.data.quiz,
                answers: response.data.quiz.answers.map((answer) => {
                    return { answer: answer }
                })
            }

            return data
        },
        createData: async (formData) => {
            await client.mutate({
                mutation: CREATE_QUIZ,
                update: addToList({ list: 'quizzes', Type: 'Quiz' }),
                variables: {
                    talk: id,
                    ...formatData(formData)
                }
            })

            message.success('Enquete cadastrada com sucesso!')
        },
        updateData: async (id, formData) => {
            const { errors } = await client.mutate({
                mutation: UPDATE_QUIZ,
                variables: {
                    id,
                    ...formatData(formData)
                }
            })

            if (errors) throw errors

            message.success('Enquete atualizada com sucesso!')
        }
    })

    return (
        <CrudModal
            width={700}
            saveLabel={isEdit ? 'Editar Enquete' : 'Salvar Enquete'}
            title={isEdit ? 'Editar Enquete' : 'Nova Enquete'}
            path={path}
            {...modalProps}
        >
            <Form layout={'vertical'} form={form}>
                <Form.Item
                    label="Dúvida"
                    name="question"
                    rules={[
                        { required: true, message: 'A questão é obrigatório!' }
                    ]}
                >
                    <Input />
                </Form.Item>
                <Card title="Respostas">
                    <Form.List name="answers" gutter={18}>
                        {(fields, { add, remove }) => (
                            <>
                                {fields.map((field, index) => (
                                    <div key={`answers.${index}`}>
                                        <Row gutter={18} align="middle">
                                            <Col span={18}>
                                                <Paragraph>
                                                    Resposta {index + 1}
                                                </Paragraph>
                                            </Col>
                                            <Col span={18}>
                                                <Form.Item
                                                    name={[
                                                        field.name,
                                                        'answer'
                                                    ]}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message:
                                                                'Resposta é obrigatório!'
                                                        }
                                                    ]}
                                                >
                                                    <Input />
                                                </Form.Item>
                                            </Col>
                                            <Col
                                                span={2}
                                                style={{ marginTop: -20 }}
                                            >
                                                <MinusCircleOutlined
                                                    style={{ marginTop: -20 }}
                                                    onClick={() =>
                                                        remove(field.name)
                                                    }
                                                />
                                            </Col>
                                        </Row>
                                    </div>
                                ))}
                                {fields.length <= 4 && (
                                    <Form.Item>
                                        <Button
                                            type="dashed"
                                            onClick={() => {
                                                add({})
                                            }}
                                            style={{ width: '100%' }}
                                        >
                                            <PlusOutlined /> Adicionar
                                        </Button>
                                    </Form.Item>
                                )}
                            </>
                        )}
                    </Form.List>
                    <Form.Item
                        label="Duração"
                        name="duration"
                        rules={[
                            {
                                required: true,
                                message: 'A duração é obrigatório!'
                            }
                        ]}
                    >
                        <InputNumber
                            min={1}
                            max={100}
                            addonAfter={<span>min</span>}
                        />
                    </Form.Item>
                </Card>
            </Form>
        </CrudModal>
    )
}

export default SurveyModal

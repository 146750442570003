import React, { useEffect, useState } from 'react'
import { useLocation, Link, useParams, useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import {
    Col,
    Button,
    Row,
    Space,
    message,
    Typography,
    Dropdown,
    Progress,
    Popconfirm,
    Modal,
    Select,
    Badge
} from 'antd'
import {
    DownOutlined,
    PlusOutlined,
    LogoutOutlined,
    CheckCircleFilled,
    ExclamationCircleFilled
} from '@ant-design/icons'
import { useApolloClient, useMutation } from '@apollo/client'

import { UPDATE_SITE } from '@cms/events/graphql/mutations/site'

import { usePageBuilderContext } from '@cms/events/pages/Config/PageBuilder/context/context'
import {
    Navigation,
    Wrapper,
    LinkStyled,
    HrefStyled,
    CustomMenu,
    ProgressContent,
    ProgressContainer,
    StyledAffix
} from './styles'

import { ROUTES } from '../../constants/routes'
import { NAVTITLES } from '../../constants/labels'
import AddPageModal from '../AddPageModal'
import { nanoid } from 'nanoid'
import { set } from 'lodash'
import Affix from '@cms/core/components/Affix'

const Nav = () => {
    const { Title } = Typography
    const { eventId } = useParams()
    const { pathname } = useLocation()
    const { customer } = useSelector((state) => state)
    const [loading, setLoading] = useState(false)
    const [addPageModal, setAddPageModal] = useState(false)
    const { config, setConfig, site, setSite } = usePageBuilderContext()
    const [page, setPage] = useState(
        pathname.match(/page\/(.*)/) ? pathname.match(/page\/(.*)/)[1] : null
    )
    const [updateSite] = useMutation(UPDATE_SITE)
    const baseUrl = `/evento/${eventId}/paginas/`
    const [menu, item] = pathname.replace(baseUrl, '').split('/')
    const [progress, setProgress] = useState(0)
    const history = useHistory()
    const { model, fieldRefValue, setFieldRefValue } = usePageBuilderContext()
    const client = useApolloClient()
    const [options, setOptions] = useState([])

    useEffect(() => {
        const loadItems = async () => {
            const filter = set(
                {
                    event: eventId
                },
                model.fieldRef,
                {
                    $ne: null
                }
            )

            const list = await client.query({
                query: model.GRAPHQL_QUERY,
                variables: {
                    filter
                }
            })

            const _options = model.formatOptions(list.data, model.fieldRef)
            setOptions(_options)
            if (_options[0]) {
                setFieldRefValue(_options[0].value)
            }
        }

        if (model) {
            loadItems()
        }
    }, [model])

    const handlePublishedSite = async () => {
        const { id } = config

        try {
            setLoading(true)

            await updateSite({
                variables: {
                    id,
                    data: site,
                    draft: site
                }
            })

            setConfig({
                ...config,
                data: JSON.parse(JSON.stringify(site))
            })
        } catch (error) {
            message.error(error.message)
        }
    }

    const mountPath = (path) => {
        return baseUrl + path
    }

    useEffect(() => {
        if (loading) {
            let percentAux = 0

            let interval = setInterval(() => {
                if (percentAux === 100) {
                    interval = clearInterval(interval)

                    message.success('Site publicado com sucesso!')

                    setLoading(false)
                    setProgress(0)

                    return
                }

                percentAux += 20
                setProgress(percentAux)
            }, 1000)
        }
    }, [loading])

    const progressSave = () => {
        return (
            <ProgressContainer>
                <ProgressContent>
                    <Progress
                        type="circle"
                        strokeColor={progress === 100 ? '#52c41a' : '#7730f6'}
                        percent={progress}
                    />
                    <br />
                    Aguarde enquanto o site está sendo publicado...
                </ProgressContent>
            </ProgressContainer>
        )
    }

    const menuConfig = (
        <CustomMenu>
            {ROUTES.config.map(({ title, path }) => (
                <CustomMenu.Item
                    key={title}
                    className={
                        pathname === mountPath(path) ? 'active-item' : ''
                    }
                >
                    <Link to={mountPath(path)} onClick={() => setPage('home')}>
                        {title}
                    </Link>
                </CustomMenu.Item>
            ))}
            <Popconfirm
                title={
                    <span>
                        Ao recomeçar você perderá <b>TODAS</b> as páginas e
                        configurações.
                        <br />
                        Recomeçando a criação do inicio, selecionando um
                        template.
                        <br />
                        Você tem certeza?
                    </span>
                }
                okText="Sim"
                onConfirm={() =>
                    (window.location = `/evento/${eventId}/paginas/templates`)
                }
                cancelText="Não"
            >
                <CustomMenu.Item>Recomeçar o site</CustomMenu.Item>
            </Popconfirm>
        </CustomMenu>
    )

    const menuNavigation = (
        <CustomMenu>
            {ROUTES.navigations.map(({ title, path, name }) => (
                <CustomMenu.Item
                    key={title}
                    className={
                        pathname === mountPath(path) ? 'active-item' : ''
                    }
                >
                    <Link to={mountPath(path)} onClick={() => setPage(name)}>
                        {title}
                    </Link>
                </CustomMenu.Item>
            ))}
        </CustomMenu>
    )

    const menuPages = (
        <CustomMenu>
            <CustomMenu.Item key={nanoid()}>
                <a
                    onClick={() => {
                        setAddPageModal(true)
                    }}
                >
                    <Space>
                        <span>Adicionar Página</span>
                        <PlusOutlined />
                    </Space>
                </a>
            </CustomMenu.Item>
            <CustomMenu.Divider />
            <CustomMenu.ItemGroup title="Páginas do sistema">
                {ROUTES.pages
                    .filter(({ name }) => site.pages[name])
                    .map(({ title, path, name }) => (
                        <CustomMenu.Item
                            key={title}
                            className={
                                pathname === mountPath(path)
                                    ? 'active-item'
                                    : ''
                            }
                        >
                            <Link
                                to={mountPath(path)}
                                onClick={() => setPage(name)}
                            >
                                {site.pages[name].name || title}
                            </Link>
                        </CustomMenu.Item>
                    ))}
            </CustomMenu.ItemGroup>
            <CustomMenu.ItemGroup title="Páginas personalizadas">
                {Object.keys(site.pages)
                    .filter((key) => site.pages[key].custom)
                    .map((key) => (
                        <CustomMenu.Item
                            key={key}
                            className={
                                pathname === mountPath('page/' + key)
                                    ? 'active-item'
                                    : ''
                            }
                        >
                            <Link
                                to={mountPath('page/' + key)}
                                onClick={() => setPage(key)}
                            >
                                {site.pages[key]?.name}
                            </Link>
                        </CustomMenu.Item>
                    ))}
            </CustomMenu.ItemGroup>
        </CustomMenu>
    )

    const onCreate = async (values) => {
        const copyPage = values.copyPageSlug
            ? site.pages[values.copyPageSlug]
            : null

        const newPage = {
            custom: true,
            slug: values.slug,
            name: values.name,
            seo: copyPage?.seo || {
                title: values.name,
                description: values.name,
                keywords: [],
                image: ''
            },
            sections: copyPage?.sections || []
        }

        const { id } = config
        setLoading(true)

        site.pages[values.slug + '-' + nanoid()] = newPage
        setSite(site)

        await updateSite({
            variables: {
                id: id,
                draft: site
            }
        }).finally(() => {
            setAddPageModal(false)
            setLoading(false)
            history.push(baseUrl + 'page/' + values.slug)
        })
    }

    return (
        <>
            {loading && progressSave()}
            <Wrapper>
                <Affix offsetTop={0} zIndex={999} isNav={true}>
                    <Row className="header" justify="space-between">
                        <Col
                            className="menu-title"
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}
                        >
                            <Navigation>
                                <Col>
                                    <Dropdown
                                        overlay={menuConfig}
                                        className={
                                            menu === 'config' || !menu
                                                ? 'active-nav'
                                                : ''
                                        }
                                    >
                                        <a
                                            className="ant-dropdown-link"
                                            onClick={(e) => e.preventDefault()}
                                        >
                                            Configurações <DownOutlined />
                                        </a>
                                    </Dropdown>

                                    <Dropdown
                                        overlay={menuNavigation}
                                        className={
                                            menu === 'navigations'
                                                ? 'active-nav'
                                                : ''
                                        }
                                    >
                                        <a
                                            className="ant-dropdown-link"
                                            onClick={(e) => e.preventDefault()}
                                        >
                                            Navegações <DownOutlined />
                                        </a>
                                    </Dropdown>

                                    <Dropdown
                                        overlay={menuPages}
                                        className={
                                            menu === 'page' ? 'active-nav' : ''
                                        }
                                    >
                                        <a
                                            className="ant-dropdown-link"
                                            onClick={(e) => e.preventDefault()}
                                        >
                                            Páginas <DownOutlined />
                                        </a>
                                    </Dropdown>
                                </Col>
                            </Navigation>
                            <Title level={2}>
                                {item in NAVTITLES
                                    ? NAVTITLES[item]
                                    : site.pages[item]?.name}
                            </Title>
                        </Col>
                        <Col className="menu-preview">
                            <Space>
                                {!model && (
                                    <HrefStyled
                                        href={`${
                                            process.env.PAGE_PREVIEW_ENDPOINT
                                        }?db=${
                                            customer.db
                                        }&event=${eventId}&page=${
                                            site.pages[page]?.slug ||
                                            (page && page !== 'menu')
                                                ? page
                                                : 'home' || 'home'
                                        }`}
                                        target="_blank"
                                    >
                                        Visualizar
                                    </HrefStyled>
                                )}
                                {model && (
                                    <div className="previewCol">
                                        <HrefStyled
                                            className="floating"
                                            onClick={() => {
                                                window.open(
                                                    `${
                                                        process.env
                                                            .PAGE_PREVIEW_ENDPOINT
                                                    }?db=${
                                                        customer.db
                                                    }&event=${eventId}&page=${
                                                        page || 'home'
                                                    }&ref=${fieldRefValue}`,
                                                    '_blank'
                                                )
                                            }}
                                        >
                                            Visualizar{' '}
                                            <LogoutOutlined rotate={-45} />
                                        </HrefStyled>
                                        <Select
                                            style={{ minWidth: '198px' }}
                                            value={fieldRefValue}
                                            onChange={(value) => {
                                                setFieldRefValue(value)
                                            }}
                                        >
                                            {options.map((option) => {
                                                return (
                                                    <Select.Option
                                                        key={option.value}
                                                        value={option.value}
                                                        title={option.label}
                                                    >
                                                        {option.label}
                                                    </Select.Option>
                                                )
                                            })}
                                        </Select>
                                    </div>
                                )}

                                <Badge
                                    count={
                                        JSON.stringify(site) !==
                                        JSON.stringify(config.data) ? (
                                            <ExclamationCircleFilled />
                                        ) : (
                                            <CheckCircleFilled />
                                        )
                                    }
                                >
                                    <Button
                                        type="primary"
                                        loading={loading}
                                        disabled={
                                            JSON.stringify(site) ===
                                            JSON.stringify(config.data)
                                        }
                                        onClick={handlePublishedSite}
                                    >
                                        {JSON.stringify(site) !==
                                        JSON.stringify(config.data)
                                            ? 'Publicar rascunho'
                                            : 'Site publicado'}
                                    </Button>
                                </Badge>
                            </Space>
                        </Col>
                    </Row>
                </Affix>
            </Wrapper>
            <AddPageModal
                open={addPageModal}
                onCreate={onCreate}
                onCancel={() => {
                    setAddPageModal(false)
                }}
            />
        </>
    )
}

export default Nav

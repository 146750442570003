import React from 'react'

import { Typography } from 'antd'
import { Wrapper } from '@cms/core/components/Theme/styles'

import { ISectonButtonProps } from './types'
import { ButtonTheme } from '@cms/core/components/Theme/components'

const { Title } = Typography

export const SectionButton = ({ title, name }: ISectonButtonProps) => {
    const _name = Array.isArray(name) ? [...name] : name
    return (
        <Wrapper>
            <Title className="list-label" level={4}>
                {title}
            </Title>

            <ButtonTheme name={_name} />
        </Wrapper>
    )
}


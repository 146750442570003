import React from 'react'
import { useQuery, useMutation } from '@apollo/client'

import { useCreation } from '@umijs/hooks'

import { GET_CUSTOM_TRACKER_LIST_ORDENABLE } from '@cms/events/graphql/queries/trackers'
import { DELETE_CUSTOM_TRACKER } from '@cms/events/graphql/mutations/trackers'
import { removeFromList } from '@cms/core/graphql/utils'

import {
    CrudList,
    CreateModalRoutes,
    CrudColumns
} from '@cms/core/components/Crud'

import { ReloadOutlined } from '@ant-design/icons'

import TrackerModal from './TrackersModal'

const columns = [
    {
        title: '',
        dataIndex: [''],
        render: (value, obj) => {
            return <CrudColumns title={obj.node.name} onlyText />
        }
    }
]

const orderByFields = [{ label: 'Data criação', value: 'createdAt' }]

const Trackers = () => {
    const path = `/principal/trackers`

    const variables = { first: 25, orderBy: { createdAt: 'DESC' } }

    const query = useQuery(GET_CUSTOM_TRACKER_LIST_ORDENABLE, {
        variables
    })

    const [deleteData] = useMutation(DELETE_CUSTOM_TRACKER, {
        update: removeFromList({
            list: 'customTrackers',
            Type: 'CustomTracker'
        })
    })

    const ModalRoutes = useCreation(() => {
        return CreateModalRoutes(TrackerModal, path)
    }, [])

    if (query.error) return <div>Error: {query.error}</div>

    return (
        <>
            <ModalRoutes />
            <CrudList
                header={{
                    title: 'Trackers',
                    buttons: [
                        {
                            children: <ReloadOutlined />,
                            type: 'primary',
                            action: () => {
                                query.refetch()
                            }
                        },
                        {
                            children: 'Novo tracker',
                            type: 'primary',
                            link: path + '/adicionar'
                        }
                    ]
                }}
                search={true}
                columns={columns}
                queryName={'customTrackers'}
                onDelete={deleteData}
                orderByFields={orderByFields}
                {...query}
            />
        </>
    )
}

export default Trackers

import React from 'react'

import { useMutation, useQuery } from '@apollo/client'

import {
    CreateModalRoutes,
    CrudColumns,
    CrudList
} from '@cms/core/components/Crud'
import { GET_WATCH_USERS_LIST_ORDERABLE } from '@cms/watch/graphql/queries/watchUsers'

import { DELETE_USER } from '@cms/core/graphql/mutations/user'
import WatchUserModal from './WatchUsersModal'

const path = '/usuarios'
const ModalRoutes = CreateModalRoutes(WatchUserModal, path)

const columns = [
    {
        title: 'Nome',
        dataIndex: '',
        render: (value, obj) => {
            return <CrudColumns title={obj.node.name} onlyText />
        }
    },
    {
        title: 'E-mail',
        dataIndex: [''],
        render: (_value, obj) => {
            return (
                <CrudColumns onlyText title="E-mail" text={[obj.node.email]} />
            )
        }
    },
    {
        title: 'Plano',
        dataIndex: [''],
        render: (_value, obj) => {
            return (
                <CrudColumns
                    onlyText
                    title="Plano"
                    text={[obj.node.watchPlan?.name]}
                />
            )
        }
    }
]

const orderByFields = [
    { label: 'Nome', value: 'name' },
    { label: 'Data criação', value: 'createdAt' }
]

const WatchUsers = () => {
    const query = useQuery(GET_WATCH_USERS_LIST_ORDERABLE, {
        variables: { first: 25, orderBy: { name: 'ASC' } }
    })

    const [deleteData] = useMutation(DELETE_USER, {
        refetchQueries: [
            GET_WATCH_USERS_LIST_ORDERABLE,
            {
                variables: { first: 25, orderBy: { name: 'ASC' } }
            }
        ]
    })

    if (query.error) return <div>Error</div>

    return (
        <>
            <ModalRoutes />
            <CrudList
                header={{
                    title: 'Usuários',
                    subTitle: 'Adicione e gerencie usuários',
                    buttons: [
                        {
                            children: 'Novo Usuário',
                            type: 'primary',
                            link: path + '/adicionar'
                        }
                    ]
                }}
                columns={columns}
                search={true}
                queryName={'users'}
                orderByFields={orderByFields}
                hideAction="delete"
                {...query}
            />
        </>
    )
}

export default WatchUsers

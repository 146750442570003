import React, { useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { useCreation } from '@umijs/hooks'
import { useQuery, useMutation } from '@apollo/client'
import { Tag, Row, Space, Dropdown, Button, Select } from 'antd'

import {
    CrudColumns,
    CrudList,
    CrudLinks,
    CreateModalRoutes
} from '@cms/core/components/Crud'

import BrandsModal from './BrandsModal'

import { GET_BRAND_LIST_ORDENABLE } from '@cms/events/graphql/queries/brand'
import { DELETE_BRAND } from '@cms/events/graphql/mutations/brand'
import { removeFromList } from '@cms/core/graphql/utils'
import { GET_BRAND_PLAN_LIST_ORDENABLE } from '@cms/events/graphql/queries/brandPlan'

const { Option } = Select

const columns = [
    {
        title: 'logo',
        dataIndex: '',
        width: 220,
        render: (value, obj) => {
            return (
                <CrudColumns
                    image={obj.node?.logo?.url}
                    title={obj.node.name}
                />
            )
        }
    },
    {
        title: 'plano',
        dataIndex: ['node', 'plan', 'name'],
        width: 100,
        render: (planName) => {
            return (
                <CrudColumns
                    onlyText
                    title="Plano"
                    text={[planName || '---']}
                />
            )
        }
    },
    {
        title: 'prioridade',
        dataIndex: ['node', 'priority'],
        width: 110,
        render: (value) => {
            return <CrudColumns title="Prioridade" onlyText text={[value]} />
        }
    },
    {
        title: 'tags',
        dataIndex: ['node', 'tags'],
        width: 70,
        render: (tags) => {
            return (
                <Row>
                    {tags?.map((tag) => (
                        <Tag key={tag} color="#7F44FF">
                            {tag}
                        </Tag>
                    ))}
                </Row>
            )
        }
    },
    {
        title: 'links',
        dataIndex: '',
        width: 100,
        render: (value, obj) => {
            const stand = obj.node.talkStand.url
                ? [
                      {
                          type: 'Link',
                          url: obj.node.talkStand.url,
                          title: 'Stand'
                      }
                  ]
                : null
            return <>{stand ? <CrudLinks links={stand} /> : null}</>
        }
    }
]

const BrandsList = () => {
    const { eventId } = useParams()

    const query = useQuery(GET_BRAND_LIST_ORDENABLE, {
        variables: { first: 25, eventId, orderBy: { priority: 'DESC' } }
    })

    const queryBrandPlan = useQuery(GET_BRAND_PLAN_LIST_ORDENABLE, {
        variables: { first: 1000, orderBy: { createdAt: 'DESC' } }
    })

    const [deleteData] = useMutation(DELETE_BRAND, {
        update: removeFromList({ list: 'brands', Type: 'Brand' })
    })

    if (query.error || queryBrandPlan.error) return <div>Error</div>

    const path = `/evento/${eventId}/patrocinadores`

    const ModalRoutes = useCreation(() => {
        return CreateModalRoutes(BrandsModal, path, { eventId })
    }, [eventId])

    const orderByFields = [
        { label: 'Nome', value: 'name' },
        { label: 'Data criação', value: 'createdAt' },
        { label: 'Prioridade', value: 'priority' }
    ]

    const plans = queryBrandPlan.data?.brandPlans?.edges?.map((b) => b.node)

    const customFilter = (refetch) => {
        const [plan, setPlan] = useState()

        const handlePlan = (plan) => {
            setPlan(plan)
            refetch({ plan })
        }

        return (
            <>
                <Select
                    value={plan}
                    onChange={handlePlan}
                    placeholder="Plano"
                    style={{
                        width: '150px'
                    }}
                >
                    {plans?.map((item, key) => (
                        <Option key={key} value={item.id} title={item.name}>
                            {item.name}
                        </Option>
                    ))}
                </Select>
            </>
        )
    }

    const actionsExtra = {
        dataIndex: '',
        key: 'x',
        align: 'right',
        render: (text, record) => {
            const { id, ...obj } = record.node

            return (
                <>
                    {obj?.draft?.status === 'pending' && (
                        <Space style={{ marginRight: '10px' }}>
                            <Button type="primary">
                                <Link to={path + '/' + id + '/draft'}>
                                    Aprovar edição
                                </Link>
                            </Button>
                        </Space>
                    )}
                    <Space>
                        <Button type="primary">
                            <Link to={path + '/' + id + '/dashboard'}>
                                Dashboard
                            </Link>
                        </Button>
                    </Space>
                </>
            )
        }
    }

    return (
        <>
            <ModalRoutes />
            <CrudList
                header={{
                    title: 'Patrocinadores',
                    subTitle: 'Adicione e gerencie patrocinadores',
                    buttons: [
                        {
                            children: 'Novo Patrocinador',
                            type: 'primary',
                            link: path + '/adicionar'
                        }
                    ]
                }}
                columns={columns}
                search={true}
                queryName={'brands'}
                onDelete={deleteData}
                actionsExtra={actionsExtra}
                orderByFields={orderByFields}
                customFilter={customFilter}
                {...query}
            />
        </>
    )
}

export default BrandsList

import { gql } from '@apollo/client'
import * as fragments from '../fragments'

export const GET_DAILY_OVERALL_PERFOMANCE = gql`
    query DailyOverallPerformance($eventId: ObjectID!) {
        dailyOverallPerformance(event: $eventId, limit: 30) {
            date
            registrations
            referrals
        }
    }
`
export const GET_RANKING_PARTICIPANTS = gql`
    query GetListPaticipants(
        $eventId: ObjectID!
        $first: Int
        $afterCursor: Cursor
        $search: String
    ) {
        eventRegistrations(
            first: $first
            after: $afterCursor
            orderBy: { referralReports: { total: DESC } }
            filter: { event: $eventId }
            search: $search
        ) {
            totalCount
            pageInfo {
                hasNextPage
                endCursor
            }
            edges {
                cursor
                node {
                    referralReports {
                        total
                        source {
                            copy
                            facebook
                            whatsapp
                            twitter
                            linkedin
                            email
                        }
                    }
                    user {
                        ...UserData
                    }
                }
            }
        }
    }
    ${fragments.UserData}
`

export const GET_REAL_TIME_PERFOMANCE = gql`
    query metricsDashboard($eventId: ObjectID!) {
        metricsDashboard(filter: { event: $eventId }) {
            usersInRealtime {
                totalUsersInRealtime
                userPerPlatform {
                    platform
                    platformPercentage
                    platformNumberOfUsers
                }
            }
            usersPerPage {
                page
                pagePercentage
                pageNumberOfUsers
            }
            usersPerCountry {
                country
                countryPercentage
                countryNumberOfUsers
            }
            usersPerCity {
                city
                cityPercentage
                cityNumberOfUsers
            }
        }
    }
`

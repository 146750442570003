import React from 'react'
import { useParams } from 'react-router-dom'

import { PageHeader } from '@ant-design/pro-layout'

import { Layout, Row, Col, Tabs } from 'antd'
import { RankingTalkList } from '@cms/events/components/Dashboard'
import { useQuery, gql } from '@apollo/client'

import {
    DisplayTime,
    PeakAudience,
    TotalViews
} from '@cms/events/components/Dashboard/BlockItens'
import { DashboardCard } from '@cms/events/components/Dashboard'
import { ButtonReturn } from '@cms/events/components/Buttons'
import { DashboardTabs } from '@cms/events/components/Dashboard/styles'

// TODO: Mover DashboardCard para dentro dos components
const CategoriesDashboard = () => {
    const { eventId } = useParams()
    const routeParams = useParams()
    const category = routeParams.id

    const { Content } = Layout
    const { TabPane } = Tabs

    const { loading, error, data } = useQuery(
        gql`
            query ($id: ObjectID) {
                category(id: $id) {
                    name
                }
            }
        `,
        { variables: { id: category } }
    )

    if (loading || error) return null
    return (
        <Layout>
            {
                <Content>
                    <PageHeader
                        // title={'teste'}
                        // subTitle={event.description}
                        // extra={<ButtonsHeader/>}
                        style={{ padding: '16px 0px' }}
                    />
                    <Row gutter={24}>
                        <Col span={24}>
                            <DashboardTabs
                                defaultActiveKey="1"
                                tabBarExtraContent={<ButtonReturn />}
                            >
                                <TabPane tab={data.category.name} key="1">
                                    <Row type="flex" gutter={12}>
                                        <Col span={8}>
                                            <Row
                                                gutter={[0, 12]}
                                                style={{
                                                    width: '100%',
                                                    height: '100%'
                                                }}
                                            >
                                                <Col
                                                    className="column-equal"
                                                    span={24}
                                                >
                                                    <DashboardCard>
                                                        <TotalViews
                                                            eventId={eventId}
                                                            category={category}
                                                            align={'center'}
                                                            full={true}
                                                        />
                                                    </DashboardCard>
                                                </Col>

                                                <Col
                                                    className="column-equal"
                                                    span={24}
                                                >
                                                    <DashboardCard>
                                                        <DisplayTime
                                                            eventId={eventId}
                                                            category={category}
                                                            align={'center'}
                                                            full={true}
                                                        />
                                                    </DashboardCard>
                                                </Col>

                                                <Col
                                                    className="column-equal"
                                                    span={24}
                                                >
                                                    <DashboardCard>
                                                        <PeakAudience
                                                            eventId={eventId}
                                                            category={category}
                                                            align={'center'}
                                                            full={true}
                                                        />
                                                    </DashboardCard>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col span={16}>
                                            <Row
                                                gutter={[0, 12]}
                                                style={{
                                                    width: '100%',
                                                    height: '100%'
                                                }}
                                            >
                                                <Col
                                                    className="column-equal"
                                                    span={24}
                                                >
                                                    <RankingTalkList
                                                        hideAction={'all'}
                                                        showHeader={false}
                                                        styles={{
                                                            padding: 0,
                                                            margin: 0
                                                        }}
                                                    />
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </TabPane>
                            </DashboardTabs>
                        </Col>
                    </Row>
                </Content>
            }
        </Layout>
    )
}

export default CategoriesDashboard

import React from 'react'
import { useParams, Route } from 'react-router-dom'
import { useCreation } from '@umijs/hooks'
import { useQuery, useMutation } from '@apollo/client'

import { Space } from 'antd'

import { CrudColumns, CrudList } from '@cms/core/components/Crud'
import { ButtonLink } from '@cms/events/components/Buttons'

import { GET_CATEGORY_LIST_ORDERABLE } from '@cms/events/graphql/queries/category'
import { DELETE_CATEGORY } from '@cms/events/graphql/mutations/category'
import { removeFromList } from '@cms/core/graphql/utils'
import Nav from '../../Nav'

const columns = [
    {
        title: 'Nome',
        dataIndex: '',
        render: (value, obj) => {
            return (
                <CrudColumns
                    image={obj.node?.icon}
                    title={obj.node.name}
                    text={[
                        `Palestras vinculadas: ${obj.node.talksTotalCount || 0}`
                    ]}
                />
            )
        }
    }
]

const actionsExtra = {
    dataIndex: '',
    key: 'x',
    width: 150,
    align: 'right',
    render: (text, record) => {
        const id = record.node.id

        return (
            <Space>
                <ButtonLink
                    id={id}
                    path={`dashboard`}
                    key={id}
                    text={'Dashboard'}
                />
            </Space>
        )
    }
}

const SeriesList = () => {
    const { eventId } = useParams()
    const type = 'SERIE'

    const path = `/evento/${eventId}/categories`

    const query = useQuery(GET_CATEGORY_LIST_ORDERABLE, {
        variables: {
            first: 25,
            eventId,
            orderBy: { name: 'ASC' },
            type
        }
    })

    const [deleteData] = useMutation(DELETE_CATEGORY, {
        update: removeFromList({ list: 'categories', Type: 'Category' })
    })

    if (query.error) return <div>Error</div>

    const orderByFields = [
        { label: 'Nome', value: 'name' },
        { label: 'Data criação', value: 'createdAt' }
    ]

    return (
        <CrudList
            header={{
                title: 'Séries',
                subTitle: 'Adicione e gerencie as séries',
                buttons: [
                    {
                        children: 'Nova Série',
                        type: 'primary',
                        link: path + '/series/adicionar'
                    }
                ]
            }}
            search={true}
            columns={columns}
            queryName={'categories'}
            onDelete={deleteData}
            actionsExtra={actionsExtra}
            orderByFields={orderByFields}
            contentHeader={<Nav />}
            {...query}
        />
    )
}

export default SeriesList


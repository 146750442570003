import { createGlobalStyle } from 'styled-components'
import { COLORS } from '.'

const GlobalStyle = createGlobalStyle`
    @font-face {
        font-family: 'Product Sans';
        src: url('../assets/fonts/ProductSans-Italic.ttf') format('truetype');
        font-weight: normal;
        font-style: italic;
        font-display: swap;
    }

    @font-face {
        font-family: 'Product Sans';
        src: url('../assets/fonts/ProductSans-Bold.ttf') format('truetype');
        font-weight: bold;
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        font-family: 'Product Sans';
        src: url('../assets/fonts/ProductSans-BlackItalic.ttf') format('truetype');
        font-weight: 900;
        font-style: italic;
        font-display: swap;
    }

    @font-face {
        font-family: 'Product Sans';
        src: url('../assets/fonts/ProductSans-Black.ttf') format('truetype');
        font-weight: 900;
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        font-family: 'Product Sans';
        src: url('../assets/fonts/ProductSans-Light.ttf') format('truetype');
        font-weight: 300;
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        font-family: 'Product Sans';
        src: url('../assets/fonts/ProductSans-BoldItalic.ttf') format('truetype');
        font-weight: bold;
        font-style: italic;
        font-display: swap;
    }

    @font-face {
        font-family: 'Product Sans';
        src: url('../assets/fonts/ProductSans-Thin.ttf') format('truetype');
        font-weight: 100;
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        font-family: 'Product Sans';
        src: url('../assets/fonts/ProductSans-Regular.ttf') format('truetype');
        font-weight: normal;
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        font-family: 'Product Sans';
        src: url('../assets/fonts/ProductSans-LightItalic.ttf') format('truetype');
        font-weight: 300;
        font-style: italic;
        font-display: swap;
    }

    @font-face {
        font-family: 'Product Sans';
        src: url('../assets/fonts/ProductSans-MediumItalic.ttf') format('truetype');
        font-weight: 500;
        font-style: italic;
        font-display: swap;
    }

    @font-face {
        font-family: 'Product Sans';
        src: url('../assets/fonts/ProductSans-ThinItalic.ttf') format('truetype');
        font-weight: 100;
        font-style: italic;
        font-display: swap;
    }

    @font-face {
        font-family: 'Product Sans';
        src: url('../assets/fonts/ProductSans-Medium.ttf') format('truetype');
        font-weight: 500;
        font-style: normal;
        font-display: swap;
    }


    body {
        margin: 0;
        padding: 0;
        overflow-x: hidden;
        font-family: 'Product Sans', Open-Sans, Sans-Serif !important;
        background-color: rgb(24, 17, 24);

        background-image: linear-gradient(
            90deg,
            rgba(24, 17, 24, 1) 0%,
            rgba(16, 22, 23, 1) 65%,
            rgba(10, 24, 23, 1) 100%
        );

        * {
            font-family: 'Product Sans', Open-Sans, Sans-Serif !important;
        }
        
        .ace_editor, .ace_editor * {
            font-family: "Monaco", "Menlo", "Ubuntu Mono", "Droid Sans Mono", "Consolas", monospace !important;
            letter-spacing: 0 !important;
        }
    }

    *::-webkit-scrollbar {
        width: 0.7em;
        height: 0.7em;
    }

    *::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        border-radius: 10px;
    }

    *::-webkit-scrollbar-thumb {
        background-color: darkgrey;
        border-radius: 10px;
    }

    .ant-modal .ant-modal-content {
        transform: translate(0px, 0px) !important;
    }

    button, a {
        transition: all 0.3s ease;
    }

    .ant-page-header {
        padding-left: 0;
        padding-top: 0px;

        .ant-page-header-back-button{
            padding: 8px;
            border-radius: 100%;
            background: ${COLORS.bgLight};
            display: flex;
            justify-content: center;
            align-items: center;
            transition: 0.3s;

            svg {
                fill: #fff;
            }

            &:hover {
                cursor: pointer;
                background: ${COLORS.active};
            }   
        }
    }
`
export default GlobalStyle

import React, { useState } from 'react'
import {
    FacebookIcon,
    InstagramIcon,
    LinkedinIcon,
    TwitterIcon,
    LinkIcon,
    TikTokIcon
} from '../../Icons'
import { Popover, Row, Tooltip } from 'antd'

const CrudLinks = ({ links, ...props }) => {
    const [visible, setVisible] = useState({
        Facebook: false,
        Linkedin: false,
        Twitter: false,
        Instagram: false,
        Link: false
    })
    const handleCopyUrl = (url) => {
        navigator.clipboard.writeText(url ? url : '#')
    }
    const handleVisibleChange = (type) => {
        const _visible = visible[type]
        setVisible({ ...visible, [type]: !_visible })
    }

    const Icon = ({ type, url }) => {
        if (type === 'Facebook') {
            return (
                <FacebookIcon size={24} fill="#003FEE" color="#fff" url={url} />
            )
        }
        if (type === 'Linkedin') {
            return (
                <LinkedinIcon size={24} fill="#0274b3" color="#fff" url={url} />
            )
        }
        if (type === 'Twitter') {
            return (
                <TwitterIcon size={24} fill="#29A4D9" color="#fff" url={url} />
            )
        }
        if (type === 'TikTok') {
            return <TikTokIcon size={24} fill="#000" color="#fff" url={url} />
        }
        if (type === 'Instagram')
            return (
                <InstagramIcon
                    size={24}
                    fill="#B93EFF"
                    color="#fff"
                    url={url}
                />
            )
        if (type === 'Link')
            return <LinkIcon size={24} badge="#EF6C0C" fill="#fff" url={url} />
        return '---'
    }

    return (
        <Row align="middle" style={{ paddingTop: '8px' }}>
            <span style={{ padding: '0 10px' }}>Links:</span>
            {links.length > 0
                ? links.map((item, key) => (
                      <div key={'icon-' + key} style={{ display: 'flex' }}>
                          <Popover
                              trigger="click"
                              visible={visible[item.type]}
                              onVisibleChange={() =>
                                  handleVisibleChange(item.type)
                              }
                          >
                              <Tooltip title={item.url}>
                                  <span
                                      style={{ margin: '0 5px' }}
                                      key={key}
                                      onClick={() => handleCopyUrl(item.url)}
                                  >
                                      <Icon type={item.type} url={item.url} />
                                  </span>
                              </Tooltip>
                          </Popover>
                          {item.title ? (
                              <span style={{ padding: '0 10px 0 5px' }}>
                                  {item.title}
                              </span>
                          ) : null}
                      </div>
                  ))
                : null}
        </Row>
    )
}

export default CrudLinks
